body {
  padding: 0px;
  margin: 0px;
  font-family: "HK Grotesk" !important;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: #2c2c2c !important;
  line-height: 23px;
  font-weight: normal;
}

@font-face {
  font-family: "HK Grotesk";
  src: url("../fonts/HKGrotesk-Bold.eot");
  src: url("../fonts/HKGrotesk-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/HKGrotesk-Bold.woff2") format("woff2"),
    url("../fonts/HKGrotesk-Bold.woff") format("woff"),
    url("../fonts/HKGrotesk-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "HK Grotesk";
  src: url("../fonts/HKGrotesk-Regular.eot");
  src: url("../fonts/HKGrotesk-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/HKGrotesk-Regular.woff2") format("woff2"),
    url("../fonts/HKGrotesk-Regular.woff") format("woff"),
    url("../fonts/HKGrotesk-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "HK Grotesk";
  src: url("../fonts/HKGrotesk-SemiBold.eot");
  src: url("../fonts/HKGrotesk-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/HKGrotesk-SemiBold.woff2") format("woff2"),
    url("../fonts/HKGrotesk-SemiBold.woff") format("woff"),
    url("../fonts/HKGrotesk-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Dancing Script";
  src: url("../fonts/DancingScript-Bold.eot");
  src: url("../fonts/DancingScript-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/DancingScript-Bold.woff2") format("woff2"),
    url("../fonts/DancingScript-Bold.woff") format("woff"),
    url("../fonts/DancingScript-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "HK Grotesk";
  src: url("../fonts/HKGrotesk-Medium.eot");
  src: url("../fonts/HKGrotesk-Medium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/HKGrotesk-Medium.woff2") format("woff2"),
    url("../fonts/HKGrotesk-Medium.woff") format("woff"),
    url("../fonts/HKGrotesk-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

body,
html {
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
  padding: 0px;
  color: #2c2c2c;
  font-family: "HK Grotesk";
}
ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}
ul li {
  list-style: none;
}
a {
  text-decoration: none;
}
ul li:after {
  clear: both;
}
select {
  -moz-appearance: none;
}
.clear {
  clear: both;
}
img {
  max-width: 100%;
}

/*header-css-here*/

.header-area .navbar-brand {
  height: auto;
  padding: 7px 15px;
}

span.chat_icon img {
  height: 20px;
  width: auto;
  margin-right: 2px;
}

span.chat_icon {
  display: inline;
}
.header-area .contact_box {
  float: left;
  margin: 0px 0px 0px 25px;
}
.contact-content p {
  font-size: 16px;
  color: #fff;
}
.contact-content {
  padding: 25px 0px 25px 20px;
  position: relative;
}
.contact-content p .fa.fa-phone {
  position: absolute;
  left: 0px;
  top: 39px;
}

.header-area .nav > li > a {
  padding: 10px 20px;
  font-size: 16px;
  color: #efefef;
  text-align: center;
}
.header-area .nav > li > a.btn_style {
  background: #f16a6e;
  padding: 11px 40px;
  border-radius: 60px;
  color: #fff;

  text-transform: uppercase;
  margin-left: 20px;
}
.header-area .navbar-right {
  margin-top: 27px;
}
/*header-css-here*/

/*banner-area-css-here*/
.banner_area {
  padding: 127px 0px 0px 0px;
}
.header-area {
  position: absolute;
  top: 0px;
  left: 0;
  right: 0px;
}
.banner_box h1 {
  font-size: 61px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  line-height: 71px;
  text-transform: capitalize;
}
.banner_box p {
  font-size: 18px;

  color: #fff;
  text-align: center;
  line-height: 31px;
  margin-top: 15px;
  font-weight: normal;
}
.header-area .nav > li > a:focus,
.nav > li > a:hover {
  text-decoration: none;
  background-color: transparent;
  color: #f16a6e;
}
.header-area .nav > li > a.btn_style:hover {
  background: #d0595c;
  color: #fff !important;
}
/*banner-area-css-here*/
.padding_90 {
  padding: 90px 0px;
}
.our-popular-services {
  background: #fff;
}

.tittle_heading {
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  color: #2c2c2c;
}
.our_content {
  padding: 0px 50px;
  text-align: center;
}
.our_content h1 {
  font-size: 24px;
  line-height: 47px;
  font-weight: 600;
  margin: 15px 0px 10px 0px;
}
.our_content p {
  font-size: 16px;
  line-height: 25px;
  color: #2c2c2c;
}
.top_margin75 {
  margin-top: 75px;
}

.how_it_work {
  background: #f6f8fa;
}

.tittle_box .tittlep {
  font-size: 18px;
  color: #a9a9a9;
  text-align: center;
  margin: 20px 0px 0px 0px;
}
.how_it_work .content_box {
  padding: 40px 30px 50px 30px;
  text-align: center;
}
.how_it_work .content_box span {
  display: inline-block;
  color: #fff;
  font-size: 40px;
  background: #f16a6e;
  width: 84px;
  height: 84px;
  border-radius: 100%;
  padding: 30px 0px 0px 0px;
}
.how_it_work .content_box {
  padding: 40px 30px 50px 30px;
  text-align: center;
  background: #fff;
  border-radius: 10px;
  box-shadow: 11px 0px 29px rgba(0, 0, 0, 0.05);
}
.how_it_work .content_box h1 {
  font-size: 24px;
  font-weight: bold;
  margin: 30px 0px 20px 0px;
}
.how_it_work .content_box p {
  font-size: 16px;
  line-height: 25px;
}
.milddle-box::before {
  content: "";
  position: absolute;
  width: 329px;
  height: 3px;
  background: #f16a6e;

  left: -176px;
  top: 81px;
  z-index: 1;
}
.milddle-box::after {
  content: "";
  position: absolute;
  width: 329px;
  height: 3px;
  background: #f16a6e;

  right: -176px;
  top: 81px;
  z-index: 1;
}

.testimonial {
  padding: 90px 0px 55px 0px;
}

.testimonial_heading h1 {
  margin-top: 0px;
}

.testimonial_content p {
  font-size: 22px;
  line-height: 35px;
  position: relative;
  max-width: 92%;
  margin: 0 auto;
}
.testimonial_content .carousel-inner::before {
  content: "";
  background-image: url(../images/testimonal_before.png);
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  /* left: 0px width:20px; */
  width: 100px;
  height: 72px;
  left: 14px;
  top: 6px;
  z-index: -1;
}
.testimonial_content #quote-carousel {
  padding: 0px;
  margin-top: 70px;
}
.item p {
  color: #828282;
}
.testimonial_content .carousel-indicators {
  height: 146px;
}
.testimonial_content #quote-carousel .carousel-control {
  background: none;
  color: #cacaca;
  font-size: 2.3em;
  text-shadow: none;
  margin-top: 30px;
  display: none;
}
.testimonial_content #quote-carousel .carousel-indicators {
  position: relative;
  right: 50%;
  top: auto;
  bottom: 0px;
  margin-top: 20px;
  margin-right: -19px;
}
.testimonial_content #quote-carousel .carousel-indicators .active li {
  width: 11%;
  height: auto;
  opacity: 1;
  transition: all 0.4s ease-in;
  vertical-align: middle;
  text-align: center;
  text-indent: inherit;
}
.testimonial_content #quote-carousel .carousel-indicators li {
  width: 11%;
  height: auto;
  /* opacity: 1;  */
  transition: all 0.4s ease-in;
  vertical-align: middle;
  text-align: center;
  text-indent: inherit;
}
.testimonial_content #quote-carousel .carousel-indicators li img {
  width: 40px;
  height: 40px;
  cursor: pointer;
  border: 1px solid #ccc;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  opacity: 0.4;
  overflow: hidden;
  transition: all 0.4s ease-in;
  vertical-align: middle;
  margin: 0 auto;
}
.testimonial_content #quote-carousel .carousel-indicators .active img {
  width: 50px;
  height: 50px;
  opacity: 1;
  transition: all 0.2s;
}
.testimonial_content #quote-carousel .carousel-indicators .active h3 {
  font-size: 18px;
  opacity: 1;
  transition: all 0.2s;
  font-weight: 800;
}
.testimonial_content #quote-carousel .carousel-indicators h3 {
  font-size: 14px;
  transition: all 0.2s;
  font-weight: bold;
  margin: 20px 0px 10px 0px;
}
.testimonial_content #quote-carousel .carousel-indicators .active p {
  font-size: 18px;
  opacity: 1;
  transition: all 0.2s;
}
.testimonial_content #quote-carousel .carousel-indicators p {
  font-size: 14px;
  transition: all 0.2s;
}

.testimonial_content .item blockquote {
  border-left: none;
  margin: 0;
}
.testimonial_content .item blockquote p:before {
  content: "\f10d";
  font-family: "Fontawesome";
  float: left;
  margin-right: 10px;
}
.testimonial_content .carousel-indicators li {
  margin: 0px 20px;
}
.testimonial_content .item {
  border: none;
  background: none;
}
.testimonial_content .carousel-indicators {
  position: absolute;
  bottom: 10px;
  left: 0%;
  z-index: 15;
  width: auto;
  padding-left: 0;
  margin-left: 0%;
  text-align: center;
  list-style: none;
}
.testimonial_content .carousel-inner {
  padding: 15px 0px;
}
.delivery_work {
  padding: 49px 0px 120px 0px;
}

.download {
  padding: 282px 0px 90px 0px;
  margin-top: -284px;
  background: #ffff;
}
.delivery_work_content .btn_style:hover span {
  padding-left: 10px;
  transition: all 0.2s;
}
.download_right_content {
  padding: 60px 0px 0px;
}
.download_right_content h1 {
  font-size: 36px;
  font-weight: bold;
  line-height: 72px;
}
.download_right_content p {
  font-size: 18px;
  margin: 15px 0px 45px 0px;
  line-height: 27px;
}
.download_button_list li {
  display: inline-block;
  margin: 0px 10px 0px 0px;
}
.about-tender .about_content_box {
  float: left;
  width: 50%;
  height: 670px;
}
.about-tender .about_content_box img {
  height: 670px;
  object-fit: cover;
}
.about-tender {
  background: #79a1f6;
  position: relative;
}
.audio-image {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.about-tender .about_text-box {
  padding: 30px 80px 90px 80px;
}
.about_text-box h1 {
  font-size: 40px;
  color: #fff;
  font-weight: bold;
  line-height: 27px;
  margin: 0px 0px 23px 0px;
}
.about_text-box p {
  font-size: 18px;
  color: #fff;
  line-height: 27px;
  margin: 0px 0px 23px 0px;
}
.about_text-box .company_info {
  margin: 40px 0px 0px 0px;
}

.about_text-box .company_info ul {
  display: flex;
}
.about_text-box ul li h1 {
  font-size: 34px;
  margin: 0px;
}
.left_content_list {
  padding-left: 80px;
}
.about_text-box ul li p {
  font-size: 18px;
  margin: 15px 0px 40px 0px;
}
.provider-services {
  padding: 0px 0px 65px 0px;
}

.delivery_work_content {
  padding: 50px 50px 50px 50px;
  background: url(../images/provider-image.png);
  background-position: right;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
}
.delivery_work_content h4 {
  font-size: 24px;
  font-weight: normal;
  color: #fff;
  line-height: 25px;
}
.delivery_work_content h1 {
  font-size: 36px;
  font-weight: bold;
  color: #fff;
  line-height: 25px;
  margin: 20px 0px 30px 0px;
}

.delivery_work_content .btn_style {
  display: inline-block;
  background: #fff;
  text-align: center;
  border-radius: 60px;
  font-size: 22px;
  color: #79a1f6;
  padding: 22px 35px;
  font-weight: bold;
}
.delivery_work_content .btn_style span {
  padding-left: 25px;
  transition: all 0.2s;
}
.footer {
  background: #333333;
  padding: 35px 0px;
}
.footer_content_logo_side img {
  margin-top: 0px;
  /*margin-top: 20px;*/
}
.footer_content h4 {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #fff;
}
.footer_content p {
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 15px;
  color: #c7c7c7;
  padding-left: 40px;
  position: relative;
}
.footer_content_logo_side {
  padding: 0px 11px;
}
.footer_content_logo_side p {
  font-size: 16px;
  font-weight: normal;
  margin: 15px 0px 0px 0px;
  color: #c7c7c7;
}
.footer_content p a {
  font-size: 16px;
  color: #c7c7c7;
}

.footer_content p a:hover {
  text-decoration: underline;
}
.footer_content_logo_side a {
  font-size: 16px;
  font-weight: normal;
  color: #ff9326;
}
.footer_content p:last-child {
  margin: 0px;
}
.footer_content .fa {
  position: absolute;
  left: 7px;
  top: 4px;
}
.Quick-links-list p {
  padding-left: 7px;
}

.footer_copy_right {
  padding: 15px 0px;
}
.footer_copy_right p {
  font-size: 16px;
  color: #000000;
}
.days {
  margin-right: 35px;
}
.links_box {
  padding-left: 30px;
}
/*home_page_css*/

/*login page css here*/

.login_modal {
  max-width: 570px;
  background: #fff;
  border: none;
}
.login_modal .new_modal_content {
  background-color: #fff;
  border: none;
  border: none;
  border-radius: inherit;
  -webkit-box-shadow: inherit;
  box-shadow: inherit;
  outline: 0;
  padding: 0px;
}
.new_modal_content .modal-header {
  padding: 10px 15px;
}
.new_modal_content .modal-title {
  font-size: 24px;
  font-weight: bold;
}
.new_modal_content .modal-title span {
  float: right;
  cursor: pointer;
}
.new_modal_content .modal-body {
  padding: 20px;
}
.button_box .social_button_links {
  display: block;
  margin-bottom: 15px;
  border-radius: 60px;
  text-align: center;
  color: #fff;
  font-weight: 600;
  padding: 13px 0px;
  border: 1px solid transparent;
  font-size: 16px;
}
.button_box .social_button_links:hover {
  text-decoration: none;
}
.button_box .facebook {
  background: #3b5998;
}
.button_box .google {
  background: transparent;
  color: #000000;
  border: 1px solid #000000;
  padding: 12px 0px;
}
.button_box .linkedin {
  background: #007bb6;
  margin: 0px;
}
.button_box .social_button_links .fa {
  margin-right: 8px;
  font-size: 20px;
}
.button_box .social_button_links .google_image {
  margin-right: 7px;
}
.or {
  text-align: center;
  margin: 8px 0px;
}
.or span {
  width: 32px;
  height: 32px;
  background: #ebebeb;
  display: inline-block;
  border-radius: 100%;
  font-size: 16px;
  color: #000;
  padding: 4px 0px 0px 0px;
  text-align: center;
  position: relative;
}
.or span:before {
  content: "";
  width: 200px;
  height: 1px;
  background: #ebebeb;
  position: absolute;
  top: 17px;
  left: 33px;
}
.or span::after {
  content: "";
  width: 200px;
  height: 1px;
  background: #ebebeb;
  position: absolute;
  top: 17px;
  right: 33px;
}
.input_form_box label {
  font-size: 13px;
  font-weight: bold;
  color: #2c2c2c;
}
.input_form_box .form-control {
  background: #f4f4f4;
  height: 48px;
  font-size: 18px;
  padding: 15px 20px 15px 57px;
  color: #2c2c2c;

  border: none;
  border-radius: 4px;
  box-shadow: inherit;
}

.input_form_box .form-control::placeholder {
  color: #a9a9a9;
  opacity: 1;
}
.input_form_box .input_content {
  position: relative;
}
.input_form_box .input_content img {
  position: absolute;
  left: 12px;
  top: 12px;
}
.forgot {
  text-align: end;
}
.forgot a {
  font-size: 16px;
  color: #f16a6e;
  display: block;
  text-decoration: none;
}
.main_btn a {
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  background: #79a1f6;
  display: block;
  padding: 8px 0px;
  border-radius: 60px;
  border: 2px solid #79a1f6;
  box-shadow: 4px 0px 8px rgba(121, 161, 246, 0.2);
  max-width: 300px;
  margin: auto;
}
.main_btn a:hover {
  text-decoration: none;
  background: #5c90ff;
  color: #fff;
}
.login_modal .main_btn a {
  padding: 8px 0px;
  margin: 0px auto;
  max-width: 300px;
}
.box_bottom {
  margin: 20px 0px 40px 0px;
}
.new_modal_content .modal-footer {
  padding: 0px 30px 0px 30px;
  text-align: center;
  border: none;
}
.new_modal_content .modal_footer-content {
  padding: 30px 0px 25px 0px;
  text-align: center;
  border-top: 1px solid #e5e5e5;
}
.new_modal_content .modal_footer-content p span a {
  font-size: 16px;
  color: #f16a6e;
  font-weight: 600;
  text-decoration: none;
}
.new_modal_content .modal_footer-content p span a:hover {
  text-decoration: underline;
}
.new_modal_content .modal_footer-content p {
  font-size: 16px;
}
.modal {
  background: rgba(51, 51, 51, 0.8);
}
.new_modal_content .text_box h1 {
  font-size: 18px;
  text-align: center;
  padding: 20px 22px 50px 22px;
}
.new_modal_content .reset_bottom {
  margin: 25px 0px 20px 0px;
}
.new_modal_content .sign_up_bottom {
  margin: 30px 0px;
}
/*login page css here*/

/*create profile css here*/
.form_input_box .form-control-two {
  background: #f4f4f4;
  height: 48px;
  font-size: 18px;
  padding: 15px 50px 15px 20px !important;
  color: #2c2c2c;
  border: none;
  border-radius: 4px;
  box-shadow: inherit;
  width: 100%;
}
.input_box_second textarea {
  height: 98px;
  padding: 15px 20px;
  font-size: 18px;
  color: red;
  display: block;
  width: 100%;
  border: none;
  background: #f4f4f4;
  border-radius: 4px;
  resize: none;
  color: #a9a9a9;
}
.form_input_box .input_content img {
  right: 20px;
  left: inherit;
}

.new_modal_content .next_bottom {
  margin: 20px 0px 10px 0px;
}
/*create profile css here*/

/*add profile css here*/
.welcome_heading h1 {
  font-size: 24px;
  font-weight: bold;
  color: #000;
  margin: 15px 0px 15px 0px;
}

.welcome_heading h3 {
  font-size: 60px;
  color: #f16a6e;
  font-weight: bold;
  font-family: "Dancing Script" !important;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 226px;
  height: 226px;
  border-radius: 100%;
  cursor: pointer;
}
.upload-btn-wrapper img {
  width: 226px;
  height: 226px;
  border-radius: 100%;
}
.upload-btn-wrapper span {
  font-size: 18px;
  color: #000;
  margin-left: 20px;
}
.file_upload_box {
  margin: 40px 0px 55px 0px;
}

/* The chek_box_label */
.chek_box_label {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #000;
}
.chek_box_label a {
  color: #f16a6e;
  font-weight: bold;
  text-decoration: underline;
}
/* Hide the browser's default checkbox */
.chek_box_label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 26px;
  width: 26px;
  background-color: transparent;
  border: 1px solid #9e9e9e;
  border-radius: 4px;
}

/* On mouse-over, add a grey background color */
.chek_box_label:hover input ~ .checkmark {
  background-color: transparent;
}

/* When the checkbox is checked, add a blue background */
.chek_box_label input:checked ~ .checkmark {
  background-color: #79a1f6;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.chek_box_label input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.chek_box_label .checkmark::after {
  left: 8px;
  top: 4px;
  width: 8px;
  height: 13px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.new_modal_content .submit_bottom {
  margin-top: 20px;
}
/*add profile css here*/

/*home page css here*/

/*home header here*/

.home_header {
  background: #7aa2f3;
}
.home_header .navbar-brand {
  height: auto;
  padding: 9px 15px;
}
.home_header .navbar {
  margin-bottom: 0px;
}
.home_header .nav.navbar-nav.navbar-right li a {
  font-size: 16px;
  color: #fff;
  padding: 20px 20px;
}

.home_header .nav.navbar-nav.navbar-right .dropdown-menu li a {
  color: #2c2c2c;
}

.home_header .nav > li > a:focus,
.nav > li > a:hover {
  text-decoration: none;
  background-color: transparent;
  color: #2c2c2c !important;
}
.home_header .nav.navbar-nav.navbar-right li.active a {
  color: #2c2c2c;
}
.home_header .nav.navbar-nav.navbar-right li.notification_list a {
  padding: 20px 35px 20px 20px;
}
.notification_list .notification {
  width: 8px;
  height: 8px;
  display: inline-block;
  background: red;
  border-radius: 100%;
  position: absolute;
  right: 34px;
  top: 21px;
}
.home_header .profile_image {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  margin: 6px 10px 0px 0px;
}
.home_header .btn {
  border: none;
  font-size: 16px;
  color: #fff;
  background: none;
  padding: 0px;
  margin-top: 5px;
}
.home_header .down-arrow {
  margin-left: 10px;
}
.home_header .nav.navbar-nav.navbar-right {
  margin-top: 13px;
}
.home_header .btn.active,
.btn:active {
  -webkit-box-shadow: inherit;
  box-shadow: inherit;
}
.home_header .list_hide {
  display: none;
}
/*home header here*/

.top-services-area {
  padding: 30px 0px 70px 0px;
}

.top_servies_heading .left_content h1 {
  font-size: 30px;
  font-weight: bold;
}
.top_servies_heading .left_content {
  float: left;
  width: 50%;
  padding: 11px 0px 0px 0px;
}
.top_servies_heading .right_content {
  float: right;
  width: 50%;
}
.right_content ul {
  float: right;
}
.right_content ul li {
  float: left;
  width: 238px;
}
.right_content ul li.image-list {
  width: inherit;
  padding-top: 2px;
}
.right_content ul li.image-list img {
  box-shadow: 10px 0px 15px rgba(206, 206, 206, 0.15);
}
.right_content ul li.middle_list {
  margin: 0px 10px;
}
.right_content ul li .form-control {
  background: #f4f4f4;
  font-size: 18px;
  color: #a9a9a9;
  padding: 0px 20px;
  border: none;
  border-radius: 4px;
  height: 48px;
  background-image: url(../images/select_arrow.png);
  background-repeat: no-repeat;
  background-position: 95% 56%;
  box-shadow: inherit;
}
.right_content ul li .form-group {
  margin-bottom: 0px;
}
.right_content ul li .form-control:focus {
  border-color: inherit;
  -webkit-box-shadow: inherit;
  box-shadow: inherit;
}
.top_servies_heading {
  padding: 0px 0px 30px 0px;
}
.top_servies_content {
  margin-bottom: 30px;
}
.top_servies_content .main_box {
  position: relative;
}

.top_servies_content .images-box img {
  border-radius: 10px;
  height: 270px;
  width: 100%;
  object-fit: cover;
}
.top_servies_content .images-box::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background: #000000;
  left: 0;
  top: 0;
  border-radius: 10px;
  opacity: 0.4;
}
.top_servies_content .content_box h1 {
  font-size: 24px;
  color: #fff;
  font-weight: bold;
  line-height: normal;
}
.top_servies_content .content_box p {
  font-size: 14px;
  margin: 2px 0px 10px 0px;
  color: #fff;
}
.top_servies_content .content_box p i {
  font-size: 14px;
  color: #f0c104;
  margin: 0px 3px 0px 0px;
}
.top_servies_content .content_box {
  padding: 0px 15px;
  position: absolute;
  bottom: 19px;
  width: 100%;
}
.top_servies_content .content_box h4 .left-span {
  font-size: 14px;
  color: #fff;
  font-weight: bold;
  background: red;
  border-radius: 60px;
  padding: 2px 9px;
  text-align: center;
}
.top_servies_content .content_box h4 .right-span {
  font-size: 14px;
  color: #fff;
  float: right;
}
.top_servies_content .main_box .heart-image {
  position: absolute;
  right: 14px;
  top: 14px;
  width: 40px;
  height: 40px;
  font-size: 22px;
  color: #fff;
  /* background: #f16a6e; */
  border-radius: 100%;
  text-align: center;
  padding: 10px 0px 0px 0px;
}
.top_servies_content a:hover .content_box h1 {
  color: #f16a6e;
}

/*pagination css here*/

.pagination_box .pagination > li > a,
.pagination > li > span {
  padding: 6px 13px;
  color: #000;
  background-color: #fff;
  border: 1px solid #d6d6d6;
  font-size: 18px;
}
.pagination_box .pagination {
  margin: 10px 0px 0px 0px;
}
.pagination_box .pagination a img {
  margin-top: -3px;
}
.pagination_box .page-item.active a {
  background: #7aa2f3;
  color: #fff;
  border: 1px solid #7aa2f3;
}
/*home page css here*/

/*my job css here*/

.my_job_tab_box {
  padding: 20px 0px 70px 0px;
  background: #f6f8fa;
}
.tab_heading_content .nav-tabs {
  border-bottom: none;
}

.tab_heading_content .nav-tabs > li > a {
  margin-right: 0px;
  line-height: 1.42857143;
  border: 1px solid #7aa2f3;
  border-radius: 60px 0px 0px 60px;
  padding: 12px 32px;
  font-size: 18px;
  color: #2c2c2c;
  width: 100%;
  min-width: 146px;
  text-align: center;
  max-width: 146px;
  width: 100%;
}
.tab_heading_content .nav-tabs > li.upcoming_list > a {
  border-right: transparent;
  border-top: 1px solid #7aa2f3;
  border-bottom: 1px solid #7aa2f3;
  border-radius: 0px 0px 0px 0px;
  border-left: transparent;
}
.tab_heading_content .nav-tabs > li.past-list > a {
  border-radius: 0px 60px 60px 0px;
}

.tab_heading_content .nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
  color: #fff;
  cursor: default;
  background-color: #7aa2f3;
  border: 1px solid transparent;
  /*border-bottom-color: transparent;*/
}
.tab_heading_content .add_post.button {
  float: right;
}
.tab_heading_content .add_post.button a {
  display: inline-block;
  background: #f16a6e;
  font-size: 18px;
  color: #fff;
  padding: 13px 34px;
  border-radius: 60px;
}
.tab_heading_content .add_post.button a:hover {
  background: #db565a;
  text-decoration: none;
}
.my_job_content {
  padding: 20px 0px 0px 0px;
}
.tab_content_box {
  margin-bottom: 30px;
}
.tab_content_box h1 {
  font-size: 26px;
  font-weight: 600;
}
.tab_content_box h1 a:hover {
  color: #2c2c2c;
  text-decoration: none;
}
.tab_content_box h1 a {
  color: #2c2c2c;
}
.tab_content_box h1 span {
  float: right;
}
.tab_content_box h5 {
  font-size: 16px;
  color: #a9a9a9;
  line-height: 33px;
  margin-bottom: 13px;
}
.tab_content_box h5 span {
  margin-right: 15px;
  display: inline-block;
}
.tab_content_box h5 span.calender_image {
  margin-right: 10px;
}
.tab_content_box .more_content {
  font-size: 18px;
  line-height: normal;
}
.tab_content_box .more_content .more button {
  font-size: 18px;
  color: #f16a6e;
  font-weight: 600;
}
.tab_content_box .plumber {
  font-size: 18px;
  color: #79a1f6;
  background: #f1f5fe;
  padding: 7px 26px;
  border-radius: 60px;
  display: inline-block;
  text-align: center;
  margin: 15px 10px 15px 0px;
}
.tab_content_box h6 button {
  font-size: 18px;
  color: #f16a6e;
  line-height: normal;
}

.dropdown_box .dropdown-menu {
  left: -115px;
  border-radius: 10px;
  -webkit-box-shadow: inherit;
  box-shadow: inherit;
  background-color: #141414;
  border: none;
  padding: 0px;
  top: 61px;
}
.dropdown_box .dropdown-menu li {
  padding: 0px;
  padding-left: 15px;
  border-bottom: 1px solid #fff;
}
.dropdown_box .dropdown-menu li button {
  font-size: 16px;
  color: #fff;
  padding: 0px;
}
.dropdown_box .dropdown-menu li button i {
  margin-right: 8px;
}
.dropdown_box .dropdown-menu > li > button:focus,
.dropdown-menu > li > button:hover {
  color: #fff;
  text-decoration: none;
  background-color: transparent;
}
.dropdown_box .dropdown-menu::after {
  position: absolute;
  top: -10px;
  right: 16px;
  border-top: 0px solid transparent;
  border-bottom: 10px solid #141414;
  content: "";
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
}
.dropdown_box {
  cursor: pointer;
}

.In-progress {
  font-size: 18px;
  color: #f16a6e;
  font-weight: 500;
}
.tab_content_box h1 .waiting {
  font-size: 18px;
  font-weight: 500;
  color: #f1a757;
  margin-right: 6px;
  padding-top: 3px;
}
/*my job css here*/

/*add new job css here*/
.post-new_job {
  padding: 30px 0px 90px 0px;
  background: #f6f8fa;
}
.heading-area h1 {
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  line-height: normal;
}
.new_job_main_box {
  padding: 30px 20px 40px 20px;
  background: #fff;
  box-shadow: 2px 0px 8px rgba(0, 0, 0, 0.1);
  margin-top: 30px;
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 13px;
  color: #2c2c2c;
}
.form_input_box .form-control {
  font-size: 18px;
  color: #2c2c2c;
  background: #f4f4f4;
  height: 48px;
  padding: 15px 20px;
  border-radius: 4px;
  border: none;
  box-shadow: inherit;
}

.form_input_box .form-group {
  margin-bottom: 20px;
}
.form_input_box .form-control::placeholder {
  color: #a9a9a9;
  opacity: 1;
}

.form_input_box textarea {
  height: 108px;
  width: 100%;
  font-size: 18px;
  color: #2c2c2c;
  background: #f4f4f4;
  padding: 15px 20px;
  border-radius: 4px;
  border: none;
  box-shadow: inherit;
  resize: none;
}
.form_input_box .input_box {
  position: relative;
}
.form_input_box .input_box img {
  position: absolute;
  right: 20px;
  top: 12px;
}
.attachment h1 {
  font-size: 13px;
  font-weight: 600;
  line-height: normal;
}
.attachment .format {
  font-size: 18px;
  line-height: normal;
  margin-top: 11px;
}

.upload-box .upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.upload-box .btn {
  border: 2px solid #f16a6e;
  color: #f16a6e;
  background-color: transparent;
  padding: 6px 14px;
  border-radius: 60px;
  font-size: 16px;
  font-weight: 500;
}

.upload-box .upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  height: 100%;
  width: 100%;
}
.image_text {
  font-size: 13px;
  font-weight: 600;
  margin: 30px 0px 20px 0px;
}
.image-upload_box {
  border: 2px dashed #ccc;
  text-align: center;
  border-radius: 4px;
  padding: 30px 0px;
}
.image-upload_box p {
  font-size: 16px;
  margin: 17px 0px;
}
.image-upload_box .upload-box .btn {
  padding: 6px 34px;
}
.post_button_box {
  max-width: 47%;
  margin: 35px auto auto auto;
}
/*add new job css here*/

/*preview post css here*/

.preview_post_banner_area {
  background: url(../images/preview_background_image.png);
  height: 250px;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.preview_post_banner_area::before {
  content: "";
  position: absolute;
  background: #000;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  opacity: 0.8;
}
.slider-box .carousel-inner {
  border-radius: 10px;
}
.slider-box .left.carousel-control,
.right.carousel-control {
  display: none;
}
.slider-box .carousel-indicators li {
  background-color: #898681;
  border: 1px solid #898681;
}

.slider-box .carousel-indicators {
  position: absolute;
  bottom: 14px;
  left: 20px;
  z-index: 15;
  width: auto;
  padding-left: 0;
  margin-left: 0px;
  margin-bottom: 0px;
}
.slider-box .carousel-indicators .active {
  background-color: #fff;
  border: 1px solid #fff;
}
.pulish_button-box {
  padding: 10px 30px 0px 30px;
}
.pulish_button-box .main_btn {
  margin-top: 20px;
}
.border_button a {
  background: transparent;
  color: #79a1f6;
}
.slider_right_content h1 {
  font-size: 28px;
  font-weight: 600;
  line-height: normal;
  line-break: anywhere;
}
.slider_right_content ul li p {
  font-size: 18px;
  color: #8e8d8d;
}
.slider_right_content ul li p span {
  float: right;
  color: #2c2c2c;
}
.slider_right_content ul li {
  padding: 15px 0px;
  border-bottom: 1px solid #f0f2f4;
}
.slider_right_content ul li.location {
  padding-bottom: 38px;
}
.slider_right_content ul li.location p span {
  text-align: right;
}
.slider_right_content ul li:last-child {
  padding: 15px 0px 0px 0px;
  border-bottom: none;
}
.slider_right_content ul li p.description_content {
  color: #2c2c2c;
  margin: 10px 0px 0px 0px;
  line-height: normal;
  white-space: pre-line;
}
.message_badge {
  width: 8px;
  height: 8px;
  display: inline-block;
  background: red;
  border-radius: 100%;
  position: absolute;
  right: 15px;
  top: 21px;
}
.message_content {
  position: relative;
  padding-right: 50px;
}
span.unread_message_count {
  position: absolute;
  background: #79a1f6;
  bottom: 0;
  width: 30px;
  text-align: center;
  border-radius: 100%;
  height: 30px;
  padding-top: 3px;
  color: #fff;
  right: 0;
  top: 5px;
}

.slider-box {
  margin-top: -120px;
}
.slider-box.without_image {
  margin-top: 0px;
}

.slider_preview_post {
  padding: 35px 0px 50px 0px;
}
/*preview post css here*/

/*invite user css here*/
.job_post_succesfully {
  padding: 33px 0px 45px 0px;
  background: #e4f1e5;
}
.job_post_succesfully h1 {
  font-size: 26px;
  font-weight: bold;
  color: #48b02c;
  line-height: normal;
}
.job_post_succesfully p {
  font-size: 18px;
  margin: 3px 0px 0px 0px;
}
.top_servies_heading .skip_button_content {
  text-align: right;
  width: inherit;
}
.skip_button_content .button_box a {
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  border: 1px solid #f16a6e;
  border-radius: 60px;
  color: #fff;
  background: #f16a6e;
  padding: 12px 43px;
  margin-right: 10px;
  display: inline-block;
}
.skip_button_content .button_box a.skip {
  color: #f16a6e;
  background: transparent;
  padding: 12px 62px;
  margin-right: 0px;
}
.skip_button_content .button_box a:hover {
  text-decoration: none;
  background: #f2797c;
  color: #fff;
}
.invite-service {
  padding: 20px 0px 65px 0px;
}
.invite-service-heading {
  padding: 0px 0px 20px 0px;
}
.span_chek {
  position: absolute;
  left: 14px;
  top: 14px;
}

.span_chek .radio_button_box {
  overflow: inherit;
}
.span_chek .radio_button_box .radio_box input:checked ~ .checkmark {
  background-color: #f16a6e;
  border: 2px solid #f16a6e;
}
.span_chek .radio_button_box .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 40px;
  width: 40px;
  background-color: #eee;
  border-radius: 100%;
  background: #fff;
  border: 2px solid #f16a6e;
}
.span_chek .radio_button_box .radio_box .checkmark::after {
  left: 12px;
  top: 6px;
  width: 11px;
  height: 18px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.span_chek .radio_button_box .radio_box:hover input ~ .checkmark {
  background-color: #f16a6e;
}
/* invite user css here*/

/*service provider profile css here*/

.service_provider_banner_area {
  background: url(../images/service_provider_banner.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 342px;
  background-position: center;
}
.profile_box .profile_content {
  position: relative;
  display: inline-block;
}
.profile_content .main_profile_image {
  width: 268px;
  height: 268px;
  border-radius: 100%;
}
.profile_main_box .profile_box {
  margin-top: -150px;
}
.react-datepicker__input-container .form-control {
  float: inherit;
}
.profile_box .profile_content a span {
  width: 65px;
  height: 65px;
  /* background: #f16a6e; */
  display: inline-block;
  font-size: 30px;
  color: #fff;
  border-radius: 100%;
  text-align: center;
  padding: 0px 0px 0px 0px;
  /* box-shadow: 5px 0px 20px rgba(241, 106, 110, 0.46); */
}
.profile_box .profile_content a {
  position: absolute;
  bottom: 12px;
  right: 7px;
}
.profile_main_box .admin_name h1 {
  font-size: 24px;
  font-weight: bold;
  line-height: normal;
  margin: 17px 0px 5px 0px;
}
.profile_main_box .admin_name h2 {
  font-size: 14px;
  font-weight: 500;
  color: #000;
  margin: 0px 0px 10px 0px;
}
.profile_main_box .admin_name h2 i {
  color: #f0c104;
  margin-right: 2px;
}
.profile_main_box .admin_name h3 {
  font-size: 16px;
  font-weight: 500;
  color: #000;
}
.profile_main_box .admin_name .span_box {
  margin: 10px 0px;
}
.profile_main_box .admin_name .span_box .plumber {
  margin-right: 6px;
}
.profile_main_box .admin_name .span_box span {
  display: inline-block;
  background: #f16a6e;
  border-radius: 60px;
  padding: 2px 15px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}
.profile_main_box .admin_name p {
  font-size: 16px;
  line-height: normal;
  max-width: 34%;
  margin: auto;
}
.profile_main_box .admin_name h4 {
  font-size: 18px;
  color: #79a1f6;
  font-weight: 500;
  margin: 10px 0px 40px 0px;
}

.service_category {
  padding: 40px 0px 50px 0px;
  background: #f6f8fa;
}

.service_category_main_box .nav-tabs {
  border-bottom: none;
}
.service_category_main_box .nav-tabs > li > a {
  margin-right: 0px;
  line-height: 1.42857143;
  border: 1px solid #79a1f6;
  border-radius: 0px 0px 0 0;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  color: #2c2c2c;
  padding: 13px 53px;
}
.service_category_main_box .nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
  color: #fff;
  cursor: default;
  background-color: #79a1f6;
  border: 1px solid #79a1f6;
  border-bottom-color: transparent;
}

.service_category_main_box .nav-tabs > li > a.tab_1 {
  border-radius: 60px 0px 0px 60px;
}
.service_category_main_box .nav-tabs > li > a.tab_2 {
  border-radius: 0px 60px 60px 0px;
}
.service_category_main_box .tab_box {
  width: 353px;
  margin: auto;
}
.services_tab_content h3 {
  font-size: 28px;
  font-weight: 600;
  margin: 60px 0px 20px 0px;
  padding: 0px 110px;
}
.category_content {
  background: #fff;
  padding: 30px 30px 60px 30px;
  box-shadow: 1px 4px 10px rgba(174, 174, 174, 0.15);
}
.category_content h2 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 23px;
}
.category_content p {
  font-size: 18px;
  color: #8e8d8d;
  margin-bottom: 18px;
}
.category_content p span {
  display: inline-block;
  color: #2c2c2c;
  font-weight: 600;
  font-size: 18px;
  width: 45px;
  height: 45px;
  background: transparent;
  border: 2px solid #dbdbdb;
  text-align: center;
  padding: 9px 0px 0px 0px;
  border-radius: 100%;
  margin-right: 30px;
}
.tab_bottom_button a {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  background: #f16a6e;
  border-radius: 60px;
  text-transform: uppercase;
  text-align: center;
  padding: 13px;
  display: block;
  text-decoration: none;
  cursor: pointer;
  box-shadow: 0px 10px 15px rgba(255, 147, 38, 0.15);
}
.tab_bottom_button a:hover {
  text-decoration: none;
  background: #db565a;
}
.tab_bottom_button {
  width: 233px;
  margin: 30px auto 0px auto;
}
.gallary-group {
  margin-bottom: 30px;
}
.gallary-group img {
  height: 162px;
}
.modal-body .select_list {
  font-size: 24px;
  /* font-size: ; */
  font-weight: bold;
}

.radio_button_box {
  overflow: hidden;
  width: 100%;
}

/* The radio_box */
.radio_button_box .radio_box {
  display: block;
  position: relative;
  padding-left: 50px;
  margin-bottom: 25px;
  cursor: pointer;
  font-size: 17px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #2c2c2c;
  padding-top: 6px;
  line-break: anywhere;
}

/* Hide the browser's default checkbox */
.radio_button_box .radio_box input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.radio_button_box .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 35px;
  width: 35px;
  background-color: #eee;
  border-radius: 100%;
  background: transparent;
  border: 2px solid #dbdbdb;
}
/* On mouse-over, add a grey background color */
.radio_button_box .radio_box:hover input ~ .checkmark {
  background-color: transparent;
}

/* When the checkbox is checked, add a blue background */
.radio_button_box .radio_box input:checked ~ .checkmark {
  background-color: transparent;
  border: 2px solid #f16a6e;
}

/* Create the checkmark/indicator (hidden when not checked) */
.radio_button_box .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.radio_button_box .radio_box input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.radio_button_box .radio_box .checkmark::after {
  left: 10px;
  top: 4px;
  width: 11px;
  height: 18px;
  border: solid #f16a6e;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.radio_margin {
  margin-top: 30px;
}
/*service provider profile css here*/

/*active job_details css here*/

.slider-box .all_bid_box {
  box-shadow: 3px 0px 11px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  margin-top: 20px;

  background: #fff;
}
.all_bid_box .heading h1 {
  font-size: 24px;
  font-weight: 500;
}
.all_bid_box .heading h1 span {
  font-size: 18px;
  font-weight: 500;
  float: right;
  padding: 3px 0px 0px 0px;
  color: #79a1f6;
}
.all_bid_box .heading h1 span a {
  font-size: 18px;
  font-weight: 500;
  color: #79a1f6;
}
.all_bid_box .heading {
  padding: 20px;
  border-bottom: 1px solid #dfe0e2;
}

.all_bid_box .all_bid_content {
  padding: 0px 20px;
}
.all_bid_content ul li {
  padding: 20px 0px;
  border-bottom: 1px solid #dfe0e2;
}
/* .all_bid_content ul li .content-box */
/* chat-link-content-box */
.all_bid_content ul li .content-box {
  position: relative;
  padding: 0px 90px 0px 77px;
}

.all_bid_content ul li .content-box.chat-link-content-box {
  position: relative;
  padding: 0px 95px 0px 77px;
}
.all_bid_content ul li .content-box h1 {
  font-size: 18px;
  font-weight: bold;
}
.all_bid_content ul li .content-box h1 a {
  font-size: 18px;
  font-weight: bold;
  color: #2c2c2c;
  text-decoration: navajowhite;
}
.all_bid_content ul li .content-box h1 span {
  font-size: 14px;
  font-weight: 400;
  color: #8e8d8d;
  float: right;
}
.all_bid_content ul li .content-box p {
  font-size: 16px;
  color: #2c2c2c;
  margin: 6px 0px;
}
.all_bid_content ul li .content-box p span {
  font-size: 25px;
  font-weight: 600;
  float: right;
  color: #79a1f6;
}
.all_bid_content ul li .content-box p a {
  font-size: 16px;
  color: #2c2c2c;
  text-decoration: none;
}
.all_bid_content ul li .content-box p i {
  margin: 0px 2px 0px 1px;
  color: #f0c104;
}
.all_bid_content ul li:last-child {
  border-bottom: none;
}

.all_bid_box ul li .content-box img {
  width: 66px;
  height: 66px;
  border-radius: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
}
.all_bid_box ul li .content-box a {
  font-size: 16px;
  color: #f16a6e;
  text-decoration: underline;
  display: inline-block;
}
.slider_right_content h2.posted {
  font-size: 16px;
  margin: 0px 0px 14px 0px;
}
.slider_right_content h2.posted span {
  color: #8e8d8d;
}

/*active job_details css here*/

/*view bid message css here*/

.view_bid_section {
  padding: 20px 0px 40px 0px;
}

.all_bids_heading h1 {
  font-size: 32px;
  font-weight: 600;
  color: #000;
  margin-bottom: 14px;
}

.profile_info_box {
  max-width: 344px;
  position: absolute;
  left: 0px;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.profile-info-List .content {
  position: relative;
  padding: 20px 0px 20px 85px;
}
.profile-info-List .content img {
  position: absolute;
  left: 0px;
  width: 73px;
  height: 73px;
  border-radius: 100%;
  top: 0px;
}
.view_bid_main_box {
  box-shadow: 2px 0px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px 0px 0px 0px;
  position: relative;
  padding: 0px 0px 0px 344px;
}
.profile-info-List .content h4 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 6px;
  position: relative;
  padding-right: 85px;
}
.profile-info-List .content h4 span {
  font-size: 14px;
  font-weight: 400;
  color: #a9a9a9;
  float: right;
  position: absolute;
  top: 0;
  right: 0;
}

.content-box.no_chat {
  padding-right: 0px !important;
}

.span_btn_style span {
  background: #f16a6e;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  border-radius: 60px;
  padding: 4px 14px;
  margin: 0px 6px 0px 0px;
}

.span_btn_style_bid span {
  padding: 4px 14px;
  margin: 0 6px 0 0;
  display: inline-block;
  margin-bottom: 6px;
}

.profile-info-List li {
  padding: 20px 15px;
  border-bottom: 1px solid #e5e5e5;
  background: #fff;
  cursor: pointer;
}
.profile-info-List li:last-child {
  border-bottom: none;
}
.profile-info-List li a {
  text-decoration: none;
}

.profile-info-List li.active {
  background: #f1f5fe;
}
.view_bid_main_box .heading h1 {
  font-size: 24px;
  font-weight: 400;
  color: #fff;
  background: #79a1f6;
  padding: 20px;
}
.view_bid_main_box .bid_message_content {
  padding: 0px 20px;
}
.bid_message_content ul li .left_content {
  float: left;
  position: relative;
  padding: 24px 0px 24px 146px;
}
.bid_message_content ul li .right_content {
  float: right;
  padding: 50px 0px 0px 0px;
}
.bid_message_content ul li .left_content img {
  width: 130px;
  height: 130px;
  border-radius: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.bid_message_content ul li .left_content h2 {
  font-size: 22px;
  font-weight: 500;
  color: #000;
  line-break: anywhere;
}
.bid_message_content ul li .left_content p {
  font-size: 16px;
  color: #2c2c2c;
  margin: 4px 0px 6px 0px;
}
.bid_message_content ul li .left_content p a {
  color: #2c2c2c;
  display: inline-block;
}
.bid_message_content ul li .left_content p i {
  color: #f0c104;
  margin: 0px 2px 0px 0px;
}
.bid_message_content ul li {
  padding: 25px 0px;
  border-bottom: 1px solid #e5e5e5;
}

.bid_message_content .button_box a {
  font-size: 15px;
  background: #79a1f6;
  border-radius: 60px;
  padding: 8px 30px;
  color: #fff;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  border: 1px solid transparent;
  box-shadow: 10px 0px 15px rgba(0, 127, 252, 0.15);
}
.bid_message_content .button_box a:hover {
  text-decoration: none;
  background: #5c90ff;
  color: #fff;
}
.bid_message_content .button_box a.decline {
  color: #f16a6e;
  background: #fff;
  border: 1px solid #f16a6e;
  margin: 0px 6px;
  box-shadow: 0px 10px 15px rgba(255, 147, 38, 0.15);
}

.bid_message_content .button_box a.chat {
  background: #f16a6e;
  border: 1px solid transparent;
  box-shadow: 10px 0px 15px rgba(255, 147, 38, 0.15);
}
.bid_message_content .button_box a.chat:hover {
  background: #db565a;
  text-decoration: none;
}

.bid_message_content .button_box a.decline:hover {
  background: #db565a;
  text-decoration: none;
  color: #fff;
}
.bid_message_content ul li .message h4 {
  font-size: 18px;
  color: #aaaaaa;
  font-weight: 400;
}
.bid_message_content ul li .message p {
  font-size: 18px;
  margin-top: 10px;
}
.bid_message_content ul li .mileston h4 {
  margin: 0px 0px 17px 0px;
}
.bid_message_content ul li .mileston .mileston_list {
  position: relative;
  padding: 0px 0px 0px 50px;
  margin-bottom: 15px;
}
.bid_message_content ul li .mileston span {
  color: #79a1f6;
  float: right;
  font-size: 18px;
  font-weight: 600;
}
.bid_message_content ul li .mileston .list_span {
  display: inline-block;
  width: 33px;
  height: 33px;
  border-radius: 100%;
  border: 2px solid #dbdbdb;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  padding-top: 3px;
  position: absolute;
  left: 0px;
  color: #2c2c2c;
}
.bid_message_content ul li .mileston p {
  white-space: pre-line;
  margin: 0px 0px 5px 0px;
  font-size: 18px;
  font-weight: 500;
}
.bid_message_content_box {
  border-left: 1px solid #e5e5e5;
}
.bid_message_content ul li:last-child {
  border: none;
}

/*view bid message css here*/

/*make payment modal css here*/
.makepayment-modal .modal-body .accept {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
}
.makepayment-modal .modal-body h4 {
  font-size: 18px;
  font-weight: 500;
  color: #8e8d8d;
  margin-top: 23px;
}
.makepayment-modal .modal-body h5 {
  font-size: 18px;
  font-weight: 500;
  padding: 20px 0px 20px 50px;
  border-bottom: 1px solid #e5e5e5;
  border-top: 1px solid #e5e5e5;
}
.makepayment-modal .modal-body h5 span {
  font-size: 18px;
  float: right;
  font-weight: 600;
  color: #79a1f6;
}

.makepayment-modal .modal-body .red_text {
  color: #f16a6e;
  font-size: 14px;
  line-height: normal;
  display: block;
  padding: 17px 0px 17px 0px;
}
.makepayment-modal .modal-body .make_payment_list li {
  float: left;
  width: 48%;
  margin: 3px;
}
/*make payment modal css here*/

/*payment  page css here*/

.payment-section-area {
  padding: 45px 0px 80px 0px;
}

.payment_box {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  padding: 0px 20px;
  background: #fff;
}
.green_text {
  font-size: 22px;
  font-weight: 500;
  color: #62d32b;
}
.payment-section-area .makepayment-box {
  padding: 0px;
  min-height: 989px;
}

.makepayment-box .payment-header h1 {
  font-size: 28px;
  font-weight: 600;
  color: #fff;
  background: #7aa2f3;
  line-height: 37px;
  padding: 15px 20px 15px 20px;
}

.makepayment-box .make_payment {
  padding: 20px;
  padding-bottom: 20px;
}
.make_payment_content h2 {
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  padding: 13px 0px 13px 0px;
}
.make_payment_content h2 span {
  font-weight: 600;
}
.make_payment_content h5 {
  font-size: 18px;
  font-weight: 500;
  padding: 20px 0px 20px 0px;
  border-bottom: 1px solid #e5e5e5;
  border-top: 1px solid #e5e5e5;
}
.make_payment_content h5 span {
  font-size: 18px;
  float: right;
  font-weight: 600;
  color: #79a1f6;
}
.make_payment_content .radio_button_box .radio_box {
  padding-top: 0px;
}
.make_payment_content .red_text {
  font-size: 16px;
  color: #f16a6e;
  padding: 13px 0px 23px 0px;
}

.payment_method_content .payment_content {
  float: left;
}

.payment_method_content .payment_chekbox {
  float: right;
}
.payment_method_content .radio_button_box {
  overflow: inherit;
}
.payment_method_content .payment_content img {
  margin-right: 10px;
  height: 41px;
}

.payment_method_content
  .radio_button_box
  .radio_box
  input:checked
  ~ .checkmark {
  background-color: #79a1f6;
  border: transparent;
}
.payment_method_content .radio_button_box .radio_box .checkmark::after {
  left: 12px;
  top: 6px;
  width: 11px;
  height: 18px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.payment_method_content h1 {
  font-size: 18px;
  color: #8e8d8d;
}
.payment_method_content ul li {
  padding: 20px 0px;
  border-bottom: 1px solid #e5e5e5;
}
.payment_method_content ul li:last-child {
  border-bottom: none;
}
.payment_method_content .add_new {
  font-size: 18px;
  color: #f16a6e;
  font-weight: 500;
  padding: 5px 0px 50px 0px;
  display: inline-block;
}
.payment_method_content .tab_bottom_button {
  width: 100%;
  margin: 0px;
}

/*modal css here*/
.payment_success {
  max-width: 470px;
  /* background: ; */
}
.payment_success .modal-header {
  padding: 0px;
  border: none;
  position: relative;
}
.payment_success .modal-content {
  box-shadow: inherit;
  background: #fff;
  border-radius: inherit;
  text-align: center;
}
.payment_success .modal-content img {
  height: 129px;
  width: 129px;
  border-radius: 100%;
}
.payment_success .modal-content .modal-body p {
  font-size: 20px;
  margin-top: 20px;
}
.payment_success .modal-content .modal-body p span {
  font-weight: 600;
}
.payment_success .modal-content .cross {
  font-size: 32px;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 1;
  cursor: pointer;
}
.payment_success .modal-body {
  padding: 25px;
}
/*modal css here*/

a {
  cursor: pointer;
}
/*payment  page css here*/

/** start message page css here **/
.message_content {
  color: #a9a9a9;
  font-size: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.view_gallery {
  color: #fff;
  text-decoration: underline;
  font-size: 14px;
  float: right;
  margin-top: 2px;
}
.view_gallery img {
  margin-right: 10px;
}
.bid_message_content.message_chatbox {
  padding: 0 0 95px 0;
  position: relative;
}
.project_short_description {
  background: #f1f5fe;
  padding: 15px 20px;
}
.project_short_description h4 {
  font-size: 20px;
  position: relative;
  font-weight: bold;
  padding-right: 130px;
}
.project_short_description h4 .job_price {
  position: absolute;
  right: 0;
  top: 0;
  color: #79a1f6;
}
.project_short_description p {
  font-size: 16px;
  margin: 8px 0px;
  position: relative;
}
.project_short_description p .view_message {
  position: absolute;
  right: 0;
  top: 0;
  color: #f16a6e;
  font-size: 14px;
  text-decoration: underline;
}
.project_short_description .button_box {
  border-top: 1px solid #e2e5ed;
  margin-top: 15px;
  padding-top: 15px;
}
.project_short_description .button_box a {
  font-weight: bold;
  min-width: 150px;
}
.bid_message_content .button_box a.decline.blue_border_btn {
  border-color: #79a1f6;
  color: #79a1f6;
  box-shadow: 0px 10px 15px rgba(121, 161, 246, 0.15);
}
.bid_message_content .button_box a.decline.blue_border_btn:hover {
  background: #79a1f6;
  color: #fff;
}
.product_innerchatbox {
  padding: 20px;
}
/*.product_innerchatbox_margin_below {
    padding-bottom: 190px;
}*/
.product_innerchatbox_margin_below {
}
.left_message {
  margin: 10px 0;
}
.chat_bubble {
  border: 1px solid #e9e9e9;
  border-radius: 40px;
  border-bottom-left-radius: 0;
  display: inline-block;
  max-width: 90%;
  padding: 15px 20px;
  margin-top: 3px;
  font-size: 18px;
}
span.message_time {
  display: block;
  color: #a9a9a9;
  font-size: 14px;
  margin-top: 4px;
}
.right_message .chat_bubble {
  background: #2c2c2c;
  border: none;
  color: #fff;
  border-radius: 40px;
  border-bottom-right-radius: 0;
}
.product_chatbox {
  min-height: 400px;
  max-height: 400px;
  overflow: auto;
}
.chat_inputarea {
  background: #f5f5f5;
  padding: 20px;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}
.attach_icon {
  position: absolute;
  left: 35px;
  top: 31px;
}
.hidden {
  display: "none";
}
.chat_inputarea .form-control {
  padding: 15px 60px;
  border: none;
  height: auto;
  border-radius: 10px;
  box-shadow: none;
  font-size: 18px;
}
.chat_inputarea button {
  position: absolute;
  right: 35px;
  top: 32px;
  font-size: 28px;
  color: #f16d71;
  border: none;
  background: none;
}
.sender_name {
  color: #f16d71;
  display: block;
  font-size: 18px;
  font-weight: 600;
}
.right_message .sender_name {
  color: #2c2c2c;
}
.offer_accept {
  background: #eafbec;
  padding: 14px 20px;
  margin: 0px;
  min-height: auto;
  /*  position: absolute;
    width: 100%;
    bottom: 70px;*/
}

.threecircle {
  height: 40px;
}

.offer_reject {
  background: #fef0f0;
}
.offer_accept h4 {
  font-weight: bold;
  color: #47c759;
  font-size: 18px;
  margin: 10px 0;
}
.offer_accept img {
  height: 50px;
}
.offer_accept p {
  font-size: 16px;
}
.offer_reject h4 {
  color: #f16a6e;
}
/** end message page css here **/

/*message_21(b) page css here*/

.bid_message_content .nav.nav-tabs li {
  border: none;
  padding: 0px;
}

.bid_message_content .nav.nav-tabs li a {
  max-width: 170px;
  text-align: center;
  padding: 13px 50px;
}

.bid_message_content .message_image_box {
  padding: 20px 20px 0px 20px;
  min-height: 800px;
}

.gallery_image_box {
  margin-top: 25px;
}
/*message_21(b) page css here*/

/*job in progree css here*/

.view_job_details {
  font-size: 16px;
  color: #f16a6e;
  display: inline-block;
  margin: 13px 0px 23px 0px;
  text-decoration: underline;
}
.view_job_details:hover {
  color: #f16a6e;
  text-decoration: underline;
}
.slider_right_content .bid_message_content ul li {
  border-bottom: none;
  border-top: 1px solid #e5e5e5;
}
.slider_right_content .bid_message_content ul li p {
  color: #2c2c2c;
}
.slider_right_content .bid_message_content ul li p.green-text {
  color: #47c759;
}
.slider_right_content .bid_message_content ul li p.blue_text {
  color: #79a1f6;
}
.slider_right_content .bid_message_content ul li p.orange_text {
  color: #f1a757;
}
.color_blue {
  color: #79a1f6 !important;
}
.pink_button {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background: #f16a6e;
  border-radius: 60px;
  text-transform: capitalize;
  text-align: center;
  padding: 8px 30px;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  box-shadow: 0px 10px 15px rgba(255, 147, 38, 0.15);
  border: 1px solid #f16a6e;
}

.pink_border {
  background: #fff;
  color: #f16a6e;
  margin-left: 4px;
}
.pink_button:hover {
  text-decoration: none;
  background: #db565a;
  color: #fff;
}
.pay_now {
  margin-top: 15px;
}

.slider-box .hire_applicant {
  padding-bottom: 0px;
}
.progress_bottom {
  padding: 20px;
  background: #fef0f0;
}
.progress_bottom p {
  font-size: 18px;
  color: #f16a6e;
  text-align: center;
}
.hire_applicant ul li .content-box {
  padding-top: 12px;
}

.all_bid_box ul li .content-box img.chat_image {
  width: 51px;
  height: 51px;
  border-radius: 100%;
  position: relative;
  left: inherit;
}
.all_bid_box ul li .content-box .chat_link {
  position: absolute;
  right: 0px;
  top: 10px;
  left: inherit;
}
.payment_success .approve_content i {
  font-size: 32px;
  float: right;
}
.payment_success .approve_content p {
  margin-bottom: 20px;
}
.approve_content .approve_list li {
  float: left;
  width: 48%;
  margin: 0px 3px;
}
.reject_content .approprate_reason {
  text-align: left;
}
.reject_content .approprate_reason h1 {
  font-size: 18px;
  color: #8e8d8d;
  font-weight: 500;
  margin: 20px 0px;
}
/*job in progree css here*/

/*all mileston completed css here*/

.hire_applicant .milestone_completed p {
  color: #47c759;
  margin-bottom: 7px;
}
.hire_applicant .milestone_completed {
  background: #ecf9ee;
}
.view_bid_rating {
  margin: 10px 0px;
}
.view_bid_rating i {
  font-size: 40px;
  color: #e5b614;
  margin: 0px 4px;
}
.hire_applicant .cancle-Job {
  background: #fef6ee;
}
.hire_applicant .cancle-Job p {
  color: #f1a757;
}
/*all mileston completed css here*/

/*job completed css here*/

.hire_applicant .job_completed {
  background: #ecf9ee;
}
.hire_applicant .job_completed p {
  color: #47c759;
}
.bid_message_content ul li .feed-back {
  margin-top: 10px;
}
.bid_message_content ul li .feed-back p {
  margin-top: 5px;
}
.bid_message_content ul li .message p.feedback-text {
  margin-top: 14px;
  font-weight: 500;
}
.bid_message_content ul li .message p.rating-text {
  color: #f0c104;
}

.navbar-toggle .icon-bar {
  background: #fff;
}
/*job completed css here*/

/** start my profile page css here **/
.profile_detailarea {
  background: #f0f4fa;
  padding: 40px 0;
}
.profile_picarea {
  position: absolute;
  left: 0;
  top: 0;
}
.profile_detail_outer {
  position: relative;
  padding-left: 300px;
  min-height: 300px;
}
.user_profile_image {
  border-radius: 15px;
  width: 270px;
  height: 270px;
}
.change_profilepic {
  position: relative;
  text-align: center;
  color: #79a1f6;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 10px;
}
.change_profilepic input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
}
.profile_detail_outer h1 {
  font-weight: 600;
  padding-top: 35px;
}
.ratings_area {
  color: #e5b614;
  font-size: 20px;
  margin: 8px 0;
}
.ratings_area .reviews_count {
  color: #2c2c2c;
}
.profile_detail_outer p {
  font-size: 18px;
}
.profile_detail_outer label {
  color: #8e8d8d;
  font-weight: normal;
  margin-bottom: 3px;
  font-size: 18px;
  margin-top: 30px;
}
.my_profile_settings {
  padding: 40px 0 60px;
  background: #f6f8fa;
}
.profile_tabsarea {
  background: #fff;
  box-shadow: 2px 0px 8px rgba(0, 0, 0, 0.1);
}
.profile_tabsarea h2 {
  background: #79a1f6;
  color: #fff;
  padding: 15px;
  font-size: 20px;
}
.profile_tabsarea .nav,
.profile_tabsarea .nav li:last-child {
  border: none;
}
.profile_tabsarea .nav li {
  float: none;
  margin: 0;
  border-bottom: 1px solid #d4d4d4;
}
.profile_tabsarea .nav li a {
  margin: 0;
  border: none;
  font-size: 18px;
  color: #2c2c2c;
  padding: 15px;
  border-radius: 0;
}
.profile_tabsarea .nav li a:hover,
.profile_tabsarea .nav-tabs > li.active > a,
.profile_tabsarea .nav-tabs > li.active > a:focus,
.profile_tabsarea .nav-tabs > li.active > a:hover {
  background: #f1f5fe;
  color: #2c2c2c;
  border: none;
}
.my_profile_settings .new_job_main_box {
  margin: 0;
  padding: 30px;
}
.my_profile_settings .new_job_main_box .form_input_box {
  margin: 30px 60px;
}
.my_profile_settings .new_job_main_box .my_favorites-box {
  margin: 30px 0px;
}
.head_content_paragraph {
  color: #8e8d8d;
  font-size: 18px;
  margin-top: 8px;
}
.tab-pane .post_button_box {
  max-width: 100%;
  text-align: center;
}
.tab-pane .post_button_box .main_btn a {
  min-width: 235px;
  display: inline-block;
  background: #f16a6e;
  border: none;
}
.tab-pane .post_button_box .main_btn a:hover {
  background: #d75256;
}
.tab-pane .post_button_box .main_btn a.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover {
  background-color: inherit;
  border-color: inherit;
}
.radio-box-over {
  overflow: inherit;
}
.payment_method-details p {
  font-size: 20px;
  font-weight: 400;
  padding: 15px 0px;
}
.payment_method-details .bank_info_box img {
  position: absolute;
  left: 0px;
  top: 5px;
}
.payment_method-details .bank_info_box span {
  font-size: 14px;
  color: #8e8d8d;
}
.payment_method-details {
  padding: 0px 0px;
}
.payment_method-details .bank_info_box {
  position: relative;
  padding-left: 45px;
}
.border-bottom {
  border-bottom: 1px solid #e5e5e5;
}

.payment_method-details .bank_dlt {
  display: inline-block;
  width: 38px;
  height: 38px;
  color: #fff;
  font-size: 21px;
  background: #f16a6e;
  border-radius: 100%;
  text-align: center;
  padding: 0px 0px 0px 0px;
  position: absolute;
  right: 0px;
  top: 0px;
}

/** end my profile page css here **/

.logo_area img {
  width: 90px;
}
.select-accout-heading {
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  margin: 15px 0px;
}

.pink_btn a {
  background: #f16a6e;
  border-color: #f16a6e;
}
.pink_btn a:hover {
  text-decoration: none;
  background: #db565a;
}

.upload_content .upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.upload_content .btn {
  border: 1px solid #79a1f6;
  color: #fff;
  background-color: #79a1f6;
  padding: 10px 29px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}
.upload_content .upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
}
.upload_content .upload_file-btn-box {
  position: absolute;
  right: 0px;
  top: 0px;
}
.input-upload .form-control {
  padding: 15px 137px 15px 20px;
  color: #2c2c2c;
}
.validation {
  font-size: 18px;
  padding-bottom: 20px;
}
/*upload document css here*/

/*profile review modal css here*/
.profile-review-content {
  margin-top: 10px;
}
.profile-review-content img {
  width: 110px;
  height: 110px;
  border-radius: 100%;
}
.profile-review-content h1 {
  font-size: 26px;
  font-weight: bold;
  margin: 23px 0px 13px 0px;
}
.profile-review-content p {
  color: #213446;
  font-size: 18px;
  margin-bottom: 5px;
}
/*profile review modal css here*/

/*provider home page css here*/

.top-service-provider-area {
  background: #f6f8fa;
}
.my-job-content-two .plumber {
  margin: 15px 10px 0px 0px;
}
/*provider home page css here*/

/*job details css here*/
.all_bid_content ul li .content-box p span.job-posted-10 {
  font-weight: bold;
  float: inherit;
}
.all_bid_content ul li .content-box h1 span.job-posted {
  font-size: 16px;
  font-weight: bold;
  color: #2c2c2c;
  float: inherit;
  word-break: initial;
}
.all_bid_content ul li .job-detial-content-box {
  padding-left: 0px;
}
.job-detial-content-box .left-side-content {
  float: left;
  width: 30%;
  padding-top: 12px;
}
.job-detial-content-box .job-detils-left-box {
  width: 70%;
  padding-left: 77px;
}
.job-details-button-box {
  padding: 20px 20px 0px 20px;
}
/*job details css here*/

/* submit bid css here*/
.submit-bid_form-box {
  border-top: 1px solid #f0f2f4;
}
.submit-bid_form-box h1 {
  font-size: 23px;
  font-weight: 600;
  margin: 33px 0px 13px 0px;
}
.submit-bid_form-box .form_input_box textarea.bid-message-textarea {
  height: 137px;
}
.submit-bid_form-box .form_input_box textarea {
  height: 87px;
}
.submit-bid_form-box .add-new-button-box {
  text-align: right;
}
.submit-bid_form-box .add-new-button-box a {
  color: #f16a6e;
  background: #fff;
  border: 1px solid #f16a6e;
  margin: 0px 6px;
  box-shadow: 0px 10px 15px rgba(255, 147, 38, 0.15);
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  border-radius: 60px;
  padding: 6px 20px;
  display: inline-block;
  max-width: 120px;
  width: 100%;
}
.submit-bid_form-box .add-new-button-box a:hover {
  background: #db565a;
  text-decoration: none;
  color: #fff;
}
/* submit bid css here*/

/*my job css here*/
.invites-button {
  float: right;
  padding-top: 10px;
}
.invites-button button.invites {
  font-size: 25px;
  font-weight: 500;
  color: #f16a6e;
  display: inline-block;
}
.invites-button button.invites i {
  margin-right: 10px;
}
/*my job css here*/

/*job invites css here*/

.job-invitation-heading {
  padding-bottom: 0px;
}

/*job invites css here*/

/*job invites details css here*/
.margin-top15 {
  margin-top: 15px;
}
/*job invites details css here*/

/*accept invitaion model css here*/
.accept_invitaion-modal {
  max-width: 470px;
}
.accept_invitation-modal_box h1 {
  font-size: 20px;
  text-align: center;
  padding: 10px 20px;
}
.accept_invitation-modal_box h1 span {
  font-weight: 500;
}
/*accept invitaion model css here*/

/*chat css here*/

.message-button-box .main_btn {
  max-width: 187px;
  width: 100%;
  display: inline-block;
  margin: 0px 5px;
}

/*chat css here*/
.tab_content_box h1 span.comleted {
  font-size: 20px;
  font-weight: 500;
  color: #47c759;
}

/*my profile css here*/
.form_input_box .information_heading {
  font-size: 22px;
  font-weight: 600;
  padding: 13px 0px 20px 0px;
}
.form_input_box .padding-top {
  padding-top: 23px;
}
/*my profile css here*/

span.multiselect-native-select {
  position: relative;
}
span.multiselect-native-select select {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px -1px -1px -3px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  left: 50%;
  top: 30px;
}
.multiselect-container {
  position: absolute;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.multiselect-container .input-group {
  margin: 5px;
}
.multiselect-container > li {
  padding: 0;
}
.multiselect-container > li > a.multiselect-all label {
  font-weight: 700;
}
.multiselect-container > li.multiselect-group label {
  margin: 0;
  padding: 3px 20px 3px 20px;
  height: 100%;
  font-weight: 700;
}
.multiselect-container > li.multiselect-group-clickable label {
  cursor: pointer;
}
.multiselect-container > li > a {
  padding: 0;
}
.multiselect-container > li > a > label {
  margin: 0;
  height: 100%;
  cursor: pointer;
  font-weight: 400;
  padding: 3px 0 3px 30px;
}
.multiselect-container > li > a > label.radio,
.multiselect-container > li > a > label.checkbox {
  margin: 0;
}
.multiselect-container > li > a > label > input[type="checkbox"] {
  margin-bottom: 5px;
}
.btn-group > .btn-group:nth-child(2) > .multiselect.btn {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.form-inline .multiselect-container label.checkbox,
.form-inline .multiselect-container label.radio {
  padding: 3px 20px 3px 40px;
}
.form-inline .multiselect-container li a label.checkbox input[type="checkbox"],
.form-inline .multiselect-container li a label.radio input[type="radio"] {
  margin-left: -20px;
  margin-right: 0;
}
.selectnew {
  position: relative;
}

.multiselect.dropdown-toggle.btn-block.text-left.btn.btn-default {
  background: #f4f4f4;
  font-size: 18px;
  color: #a9a9a9;
  padding: 0px 20px;
  border: none;
  border-radius: 4px;
  height: 48px;
  box-shadow: inherit;
  text-align: left;
}
.multiselect.dropdown-toggle.btn-block.text-left.btn.btn-default
  .fa.fa-angle-down {
  font-size: 24px;
  vertical-align: middle;
  float: right;
  color: #7aa2f3;
}
.form_input_box
  .multiselect.dropdown-toggle.btn-block.text-left.btn.btn-default
  .fa.fa-angle-down {
  color: #2c2c2c;
}
.input_box_second
  .multiselect.dropdown-toggle.btn-block.text-left.btn.btn-default
  .fa.fa-angle-down {
  color: #2c2c2c;
}

.category-span {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  background: #f16a6e;
  padding: 4px 10px;
  border-radius: 60px;
  text-align: center;
  display: inline-block;
  margin-top: 10px;
  margin-right: 8px;
}
.category-span img {
  margin-left: 10px;
}

.main_header_box .nav > li > a:focus,
.main_header_box .nav > li > a:hover {
  color: #f16a6e !important;
}
.gallery_image_box img {
  width: 267px;
  height: 162px;
  object-fit: cover;
}

.view_gallery_area {
  display: block;
}
.close_gallery {
  float: right;
}
.multiselect-container.dropdown-menu li {
  float: inherit;
}
.filter_rating {
  font-size: 40px;
  color: #f0c104;
  margin-bottom: 10px;
}
.filter-group .selectnew {
  margin: 0px 0px 10px 0px;
}
.use_current_location {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 7px;
}
.payment-by_box p {
  font-size: 18px;
  font-weight: 500;
  color: #f16a6e;
  float: right;
}
.payment-by_box p span {
  color: #2c2c2c;
  margin: 0px 0px 0px 3px;
}
.my-transaction_box .tab_content_box h1 {
  font-size: 26px;
  font-weight: 600;
  line-height: normal;
}
.my-transaction_box .milestones_box h1.heading {
  font-size: 18px;
  font-weight: bold;
  color: #f16a6e;
  margin: 10px 0px 10px 0px;
}

.my-transaction_box .milestones_box_content span.rouned {
  width: 30px;
  height: 30px;
  border: 1px solid #2c2c2c;
  border-radius: 100%;
  display: inline-block;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  padding: 2px 0px 0px 0px;
  position: absolute;
  top: -3px;
  left: 0px;
}
.my-transaction_box .milestones_box_content h3 {
  font-size: 20px;
  font-weight: 600;
}
.my-transaction_box .milestones_box_content h3 span {
  font-size: 20px;
  font-weight: 600;
  float: right;
  color: #f16a6e;
}
.my-transaction_box .milestones_box_content p {
  font-size: 16px;
  font-weight: 400;
  color: #a9a9a9;
}
.my-transaction_box .milestones_box_content {
  position: relative;
  padding-left: 40px;
}
.my-transaction_box .milestones_box_content p span {
  font-weight: 600;
  color: #79a1f6;
  float: right;
}
.my-transaction_box .milestones_box_content p span img {
  width: 20px;
  height: 20px;
}

.datepicker-dropdown:before {
  display: none !important;
}
.datepicker .icon-arrow-left:after {
  content: "<<";
}
.datepicker .icon-arrow-right:after {
  content: ">>";
}
.datepicker table {
  width: 100%;
}
.date_picker.inline-datepicker {
  max-width: 180px;
}

.submint_review_modal {
  max-width: 470px;
}
.submint_review_modal .form_input_box .form-group {
  margin-bottom: 10px;
}
.Profile_dropdown li a {
  padding: 10px !important;
  text-align: center;
}
.addnew_card {
  font-size: 18px;
  color: #f16a6e;
  font-weight: 500;
  padding: 5px 0px 50px 0px;
  display: inline-block;
}
.addnew_card:hover {
  color: #f16a6e;
}
.upload-image_btn {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  background: #f16a6e;
  border-radius: 60px;
  text-transform: capitalize;
  text-align: center;
  padding: 13px;
  display: block;
  text-decoration: none;
  cursor: pointer;
  box-shadow: 0px 10px 15px rgba(255, 147, 38, 0.15);
  float: right;
  width: 100%;
  max-width: 233px;
  border: none;
}

.upload-image_btn input[type="file"] {
  opacity: 0;
  position: absolute;
}

.upload-image_btn:hover {
  text-decoration: none;
  background: #db565a;
  color: #fff;
}
.services_tab_content h3.gallay-heading {
  margin: 60px 0px 35px 0px;
}
.tab-content.new_job_main_box h2 {
  font-size: 23px;
}

/*notification dropdown menu css here*/
.notificaition-dropdown-menu {
  overflow: auto;
  max-height: 483px;
  display: none;
  z-index: 9999;
  width: 320px;
  transition: all 0.4s ease-in-out;
  border-top: 0;
  background: #fff;
  color: #000;
  box-shadow: 0px 1px 10px 0 rgba(0, 0, 0, 0.15);
}

.notificaition-dropdown-menu li a {
  padding: 0px !important;
}
.notificaition-dropdown-menu li {
  padding: 15px;
}
.notificaition-dropdown-menu {
  padding: 0px;
}
.notification-heading {
  padding: 15px;
}
.notification-heading span {
  font-size: 20px;
  float: left;
}
.notification-two {
  float: right;
  width: 20px;
  height: 20px;
  background: red;
  padding: 0px !important;
  border-radius: 100%;
  color: white;
  text-align: center;
  font-size: 12px !important;
  line-height: normal;
  padding-top: 2px !important;
}
.notificaition-dropdown-menu li a {
  position: relative;
  padding-left: 37px !important;
}
.notiprofile {
  display: inline-block;
  width: 30px;
  height: 30px;
  position: absolute;
  left: 0px;
  top: 0px;
  border-radius: 100%;
}
.notiprofile img {
  width: 30px;
  height: 30px;
  border-radius: 100%;
}
.notificaition-dropdown-menu li a h6 {
  font-size: 16px;
  white-space: break-spaces;
}
.notificaition-dropdown-menu li a p {
  font-size: 14px;
  margin-top: 4px;
  color: #737373;
}
.home_header .dropdown.notification-menu-bar {
  display: none;
}
/*notification dropdown menu css here*/
/*notification page css here*/
.notification-content {
  padding: 40px 0px;
}
.notification_content-box h1 {
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  padding-bottom: 40px;
}
.notification_content-box h1 i {
  float: left;
}
.notification_page_list li {
  border-bottom: 1px solid #f2f2f2;
  padding: 25px 0px;
}
.notification_page_list li h2 {
  font-size: 24px;
}
.notification_page_list li h2 span {
  font-weight: 600;
  color: #79a1f6;
}
.notification_page_list li p {
  color: #a9a9a9;
  font-size: 17px;
  margin-top: 7px;
}

.view_all_button {
  font-size: 18px;
  color: #f16a6e;
  font-weight: 600;
  float: right;
  padding-right: 20px;
  padding-bottom: 20px;
}

.slide img {
  height: 100%;
  /*object-fit: cover;*/
}

.slide div {
  height: 360px;
  border-radius: 5px;
  overflow: hidden;
}

.right_message.text-right img {
  width: 230px;
  margin: 0 0 0 auto;
}

.chat_image {
  height: 200px;
  max-width: 370px;
}

.chat_timestamp {
  display: flex;
  justify-content: center;
  color: #999;
  font-weight: 600;
  font-size: 12px;
  margin: 10px 0px;
  text-transform: uppercase;
}

/*notification page css here*/

.fav_provider_image {
  height: 200px !important;
}

.cursor_pointer {
  cursor: pointer;
}

.email_overflow {
  width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.review_modal_ul {
  max-height: 500px;
  overflow: auto;
}

.eyeCustomIcon {
  position: absolute;
  left: auto;
  right: 10px;
  text-indent: 32px;
  top: 15px;
}
.relative-boxcustomIcon {
  position: relative;
}
.color_blue {
  color: blue;
}
.seach-bar-main-box {
  margin-top: 25px;
  margin-left: 0px;
  position: relative;
  float: right;
  width: 30%;
}

.seach-bar-main-box i {
  color: #2c2c2c;
  position: absolute;
  right: 10px;
  top: 12px;
  font-size: 18px;
}

.seach-bar-main-box input {
  border-radius: 5px;
  border: 1px solid #9a9a9a;
  height: 40px;
  padding: 10px;
  color: #2c2c2c;
  font-size: 16px;
  padding-right: 30px;
  width: 100%;
}

.google-places-autocomplete__suggestions-container {
  padding: 0px;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.1);
  background: #fff;
}

.google-places-autocomplete__suggestion.active {
  background: #ebf2fe;
}

.google-places-autocomplete__suggestion:hover {
  background: #ebf2fe;
}

.google-places-autocomplete__suggestion {
  padding: 8px 0px;
  position: relative;
  padding-left: 30px;
}

.google-places-autocomplete__suggestion::before {
  content: "\f041";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  margin-left: 5px;
  color: #b3b3b3;
  font-size: 23px;
  position: absolute;
  left: 0px;
  top: 7px;
}

.google-places-autocomplete__suggestion:hover::before {
  color: #fe5d64;
}

.google-places-autocomplete__suggestion.active::before {
  color: #fe5d64;
}

#my_payment_method .payment_method-details {
  padding: 20px 0px;
}

button:active:focus {
  outline: 0px;
}

.testimonial_content .slide div {
  height: auto;
}
.no-content {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  /* min-height: 140px; */
  padding: 100px 0px;
}

.no-text-message {
  position: absolute;
  left: 50%;
  top: 42%;
  transform: translate(-50%, -50%);
}

.no-text-message span {
  background: #7aa2f3;
  border-radius: 30px;
  color: #fff;
  font-size: 16px;
  padding: 2px 22px;
  display: inline-block;
  text-align: center;
}

.MuiGrid-spacing-xs-8 > .MuiGrid-item {
  padding: 15px !important;
  height: 160px !important;
  margin-bottom: 0px !important;
}

.PreviewList-smallPreviewImg-115 {
  width: 100% !important;
  height: 100% !important;
}

.MuiSnackbarContent-root {
  font-size: 20px !important;
}

.right_message .chat_bubble {
  word-break: break-all;
  text-align: left;
}
.chat_bubble {
  word-break: break-word;
}

#react-google-places-autocomplete-input {
  padding-right: 55px !important;
}

.view_bid_section .no_data {
  text-align: center;
  font-size: 20px;
}

.no_data img {
  width: 156px;
}
.dlt button {
  background: none;
  border: none;
}

.dlt {
  float: right;
}
.color_grey {
  background: grey;
}

.my_job_tab_box,
.slider_preview_post,
.full_body,
.view_bid_section,
.top-services-area {
  min-height: calc(100vh - 445px);
}

ul.optionContainer li {
  width: 100%;
}
.optionListContainer .optionContainer {
  background: #fff;
}
.optionListContainer {
  left: -1px;
}
.ul-customer-detail {
  margin-bottom: 50px;
}
.ul-customer-detail li {
  font-size: 20px;
  padding: 20px 0;
  border-bottom: 1px solid #ddd;
}

.ul-customer-detail li span.pull-left {
  width: 40%;
}
.ul-customer-detail li span.pull-right {
  width: 60%;
}
.ul-customer-detail li {
  font-size: 20px;
  padding: 20px 0;
  border-bottom: 1px solid #ddd;
}

.width100 {
  width: 100% !important;
}

.profile_main_box .category_name span {
  margin: 0 5px 5px 5px;
}

/*media here*/

@media screen and (max-width: 1199px) {
  .footer_content_logo_side {
    padding: 0 0 25px 0;
  }
  /* chat-link-content-box */
  .all_bid_content ul li .content-box {
    padding: 0px 95px 0px 60px;
  }

  .all_bid_content ul li .content-box.chat-link-content-box {
    padding: 0px 90px 0px 60px;
  }

  .all_bid_content ul li .content-box h1 {
    font-size: 16px;
  }
  .all_bid_content ul li .content-box h1 span {
    font-size: 13px;
  }
  .all_bid_box ul li .content-box img {
    width: 50px;
    height: 50px;
  }
  .all_bid_content ul li .content-box p {
    font-size: 14px;
  }
  .all_bid_content ul li .content-box p span {
    font-size: 20px;
  }

  .right_content ul {
    float: inherit;
    margin-bottom: 30px;
  }
  .right_content ul li {
    float: left;
    width: 45%;
  }
  .all_bid_box ul li .content-box .chat_link {
    top: 0px;
  }
  .top_servies_heading .skip_button_content {
    text-align: center;
  }
  .all_bid_content ul li .new-job-details {
    padding: 0px;
  }
}
@media screen and (max-width: 1024px) {
}
@media screen and (max-width: 991px) {
  .footer_content {
    padding: 0px;
  }
  .footer_content h4 {
    font-size: 18px;
  }
  .top_servies_content .images-box img {
    border-radius: 10px;
    height: auto;
    width: 100%;
  }
  .top_servies_content .main_box {
    margin-bottom: 20px;
  }
  .top_servies_heading {
    padding: 0px 0px 0px 0px;
  }
  .our_content {
    margin-top: 40px;
    padding: 0;
  }
  .our-popular-services .top_margin75,
  .how_it_work .top_margin75 {
    margin-top: 0;
  }
  .milddle-box::before,
  .milddle-box::after,
  .header-area .contact_box {
    display: none;
  }
  .how_it_work .content_box {
    margin-top: 40px;
  }
  .download {
    margin: 0;
    padding: 90px 0;
  }
  .download_right_content {
    text-align: center;
    padding-top: 30px;
  }
  .about-tender .about_content_box {
    float: none;
    width: 100%;
    height: auto;
  }
  .about-tender .about_content_box img {
    height: auto;
    width: 100%;
  }
  .about-tender .about_text-box {
    padding: 90px 20px 20px 20px;
  }
  .slider-box {
    margin-bottom: 25px;
  }
  .all_bid_box ul li .content-box .chat_link {
    top: 0px;
  }
  .progress_bottom .main_btn a {
    max-width: 300px;
    margin: auto;
  }
  .top_servies_heading .skip_button_content {
    margin-bottom: 20px;
  }
  .top_servies_content .images-box::before {
    border-radius: 15px;
  }
  .my_profile_settings .new_job_main_box {
    margin-top: 30px;
  }
  .gallary-group img {
    height: inherit;
  }
  .services_tab_content h3 {
    margin: 30px 0px 20px 0px;
    padding: 0px 0px;
    text-align: center;
  }
  /** 9 april ***/
  .tab_content_box h1 {
    line-height: 32px;
    position: relative;
    padding-right: 70px;
    margin-top: 7px;
    margin-bottom: 7px;
  }
  .tab_content_box h1 span {
    float: right;
    position: absolute;
    right: 0;
    top: 5px;
  }
  .view_bid_main_box {
    padding-left: 0;
  }
  .profile_info_box {
    position: inherit;
    max-width: 100%;
    height: inherit;
  }
  .view_bid_message_box {
    display: none;
  }
  .profile-info-List .content img {
    left: 5px;
    top: 7px;
  }
  .profile-info-List li {
    padding: 10px 15px;
  }
  .view_bid_message_box.active {
    display: block;
  }
  .mobile_back {
    display: inline-block;
    margin-right: 15px;
    cursor: pointer;
  }
  .row.next_bottom .main_btn {
    margin-bottom: 15px;
  }
  .home_header .profile_image {
    width: 40px;
    height: 40px;
    margin: 9px 5px 0px 0px;
  }
  .home_header .btn {
    font-size: 14px;
  }
  .home_header .nav.navbar-nav.navbar-right li.notification_list a {
    padding: 20px 18px 20px 15px;
  }
  .notification_list .notification {
    right: 17px;
  }
  .home_header .nav.navbar-nav.navbar-right li a {
    font-size: 14px;
    padding: 20px 13px;
  }
  .filer_buttom-2 {
    margin: 0px 0px 10px 0px !important;
  }
  .my-transaction_box .tab_content_box h1 {
    padding-right: 0px;
  }
  .upload-image_btn {
    float: inherit;
    margin: 10px auto;
  }
  .upload-image_btn {
    font-size: 16px;
    padding: 8px 0px;
    max-width: 300px;
  }
}

@media screen and (max-width: 767px) {
  .footer .left_content {
    text-align: center;
    margin-bottom: 30px;
  }
  .footer {
    padding: 30px 0px 30px 0px;
  }
  .footer_content_logo_side p {
    margin: 15px 0px;
  }
  .footer .social_links {
    width: 25%;
    float: left;
  }
  .footer .links_box {
    float: left;
    width: 30%;
  }
  .footer .contact_box {
    float: left;
    width: 45%;
  }
  .home_header .list_hide {
    display: block;
  }
  .home_header .nav-list_hide {
    display: none;
  }
  .home_header .nav.navbar-nav.navbar-right {
    text-align: center;
  }
  .home_header .navbar-toggle {
    margin-top: 24px;
  }
  .top_servies_content .images-box::before {
    border-radius: 30px;
  }
  .modal-dialog {
    margin: 30px auto;
  }
  .navbar-collapse {
    background: rgba(0, 0, 0, 0.9);
    padding: 15px 30px 28px;
  }
  .header-area .navbar-right,
  .header-area .nav > li > a.btn_style {
    margin: 0;
  }

  .banner_area {
    padding: 160px 0 80px 0;
  }
  .banner_box h1 {
    font-size: 36px;
    line-height: 46px;
  }
  .banner_box p br {
    display: none;
  }
  .padding_90 {
    padding: 50px 0px;
  }
  .audio-image {
    position: static;
    transform: none;
    margin: 0px 25px 25px 25px;
  }
  .main_header_box .navbar {
    position: inherit;
  }
  .main_header_box #myNavbar {
    position: absolute;
    width: 100%;
    left: 0px;
    right: 0px;
  }
  .main_header_box .navbar-toggle {
    margin-top: 27px;
  }
  .home_header .navbar {
    position: inherit;
  }
  .home_header #myNavbar {
    position: absolute;
    /*width: 100%;*/
    left: 0px;
    right: 0px;
    z-index: 1;
  }
  .home_header .navbar-collapse {
    background: rgb(122, 162, 243);
    padding: 15px 30px 28px;
  }
  .right_content ul li {
    float: left;
    width: 44%;
  }
  .attachment {
    float: left;
  }
  .tab_content_box h1 {
    font-size: 20px;
    line-height: normal;
  }
  .tab_content_box h5 {
    font-size: 15px;
  }
  .tab_content_box h5 {
    font-size: 15px;
    position: relative;
    padding-left: 34px;
    margin-top: 10px;
    line-height: 25px;
  }
  .tab_content_box h5 span.calender_image {
    margin-right: 0px;
    position: absolute;
    left: 0px;
    top: 0px;
  }
  .gallary-image {
    text-align: center;
  }
  .gallary-image img {
    margin-bottom: 15px;
  }
  /** 9 april **/
  .tab_heading_content .nav-tabs > li > a {
    font-size: 14px;
    padding: 10px 20px;
    max-width: 100%;
  }
  .tab_heading_content .add_post.button a {
    font-size: 14px;
    padding: 9px 25px;
  }
  .modal-dialog {
    transform: scale(0.9) !important;
  }
  .tab_content_box h1 span.comleted {
    font-size: 18px;
  }
  .tab_content_box h1 {
    font-size: 18px;
  }
  .tab_content_box .more_content {
    font-size: 16px;
  }
  .top_servies_heading .job_invitation_left_content {
    width: 100%;
  }
  .home_header .dropdown.notification-menu-bar {
    display: block;
    position: absolute;
    right: 90px;
    top: 30px;
  }
  .home_header {
    position: relative;
  }
  .notification_list .notification {
    width: 8px;
    height: 8px;
    display: inline-block;
    background: red;
    border-radius: 100%;
    position: absolute;
    right: 0px;
    top: 0px;
  }
  .notificaition-dropdown-menu {
    left: -276px;
  }
}

@media screen and (max-width: 600px) {
  .footer_content p {
    font-size: 15px;
  }
  .footer .social_links {
    width: 50%;
    float: left;
  }
  .footer .links_box {
    float: left;
    width: 50%;
  }
  .footer .contact_box {
    float: left;
    width: 100%;
  }
  .modal-dialog {
    margin: 10px;
  }
  .delivery_work_content {
    padding: 20px;
    background-position: center;
  }
  .delivery_work_content h4 {
    font-size: 18px;
  }
  .delivery_work_content h1 {
    font-size: 22px;
    margin: 10px 0;
  }
  .testimonial_content p {
    font-size: 16px;
    line-height: 26px;
  }
  .testimonial_content .carousel-indicators li {
    margin: 0px 10px;
    width: 70px !important;
  }
  .testimonial_content #quote-carousel .carousel-indicators .active p {
    font-size: 12px;
  }
  .about_text-box .company_info ul {
    display: block;
  }
  .left_content_list {
    padding-left: 0;
  }
  .download_right_content h1 {
    line-height: 48px;
  }
  .download_button_list li {
    display: block;
    margin: 0px 0px 10px 0px;
  }
  .banner_box h1 {
    font-size: 30px;
    line-height: 40px;
  }
  .slider_right_content h1 {
    font-size: 20px;
  }
  .slider_right_content ul li p {
    font-size: 14px;
  }
  .or span::before {
    width: 120px;
  }
  .or span::after {
    width: 120px;
  }
  .upload-btn-wrapper img {
    width: 150px;
    height: 150px;
  }
  .upload-btn-wrapper input[type="file"] {
    width: 150px;
    height: 150px;
  }
  .welcome_heading h3 {
    font-size: 40px;
  }
  .welcome_heading h1 {
    font-size: 22px;
  }
  .file_upload_box {
    margin: 30px 0px 30px 0px;
  }
  .right_content ul li {
    float: inherit;
    width: 100%;
    margin-bottom: 15px;
  }
  .right_content ul li.middle_list {
    margin: 0px 0px 15px 0px;
  }
  .right_content ul li.image-list {
    width: inherit;
    padding-top: 0px;
    text-align: end;
  }
  .slider_right_content h2.posted {
    font-size: 15px;
  }
  .slider_right_content h1 {
    font-size: 18px;
  }
  .view_job_details {
    font-size: 14px;
    margin: 13px 0px 20px 0px;
  }
  .bid_message_content ul li {
    padding: 25px 0px;
  }
  .bid_message_content ul li .message h4 {
    font-size: 15px;
  }
  .bid_message_content ul li .message p {
    font-size: 15px;
  }
  .bid_message_content ul li .mileston p {
    font-size: 15px;
  }
  .bid_message_content ul li .mileston .mileston_list {
    padding: 0px 0px 0px 32px;
  }
  .bid_message_content ul li .mileston span {
    font-size: 15px;
  }
  .slider_preview_post {
    padding: 30px 0px 30px 0px;
  }
  .main_btn a {
    font-size: 15px;
    padding: 9px 0px;
  }
  .bid_message_content ul li .mileston .list_span {
    width: 25px;
    height: 25px;
    font-size: 15px;
    padding-top: 1px;
  }
  .progress_bottom .main_btn a {
    max-width: inherit;
    margin: auto;
  }
  .profile_detail_outer {
    position: relative;
    padding-left: 0px;
    min-height: 300px;
  }
  .profile_picarea {
    position: inherit;
    text-align: center;
  }
  .my_profile_settings .new_job_main_box .form_input_box {
    margin: 20px 0px;
  }
  .top_servies_content .images-box::before {
    border-radius: 15px;
  }
  .bid_message_content ul li .left_content img {
    width: 80px;
    height: 80px;
    top: 0;
  }
  .bid_message_content ul li .left_content {
    padding: 0px 0px 24px 90px;
  }
  .bid_message_content ul li .left_content h2 {
    font-size: 18px;
  }
  .bid_message_content ul li .left_content p {
    font-size: 14px;
  }
  .bid_message_content ul li .right_content {
    padding: 26px 0px 0px 0px;
  }
  .green_text {
    font-size: 15px;
  }
  .profile_content .main_profile_image {
    width: 200px;
    height: 200px;
  }
  .profile_box .profile_content a span {
    width: 50px;
    height: 50px;
    padding: 12px 0px 0px 0px;
  }
  .profile_main_box .profile_box {
    margin-top: -100px;
  }
  .services_tab_content h3 {
    font-size: 24px;
    margin: 30px 0px 20px 0px;
    padding: 0px;
    text-align: center;
  }
  .category_content {
    padding: 30px 30px 20px 30px;
    margin-bottom: 15px;
  }
  /** 9 april **/
  .tab_heading_content .nav {
    text-align: center;
  }
  .tab_heading_content .add_post.button {
    width: 100%;
    margin-top: 15px;
  }
  .tab_heading_content .nav-tabs > li {
    min-width: 33%;
  }
  .tab_content_box .plumber {
    font-size: 14px;
    padding: 3px 15px;
    margin: 10px 0px 10px 0px;
  }
  .view_bid_main_box .heading h1 {
    font-size: 18px;
    padding: 12px;
  }
  .mobile_back {
    margin-right: 5px;
  }
  .view_gallery img {
    height: 16px;
  }
  .project_short_description {
    padding: 15px 10px;
  }
  .project_short_description h4 {
    padding-right: 0;
  }
  .project_short_description h4 .job_price {
    position: inherit;
    display: block;
    margin-top: 10px;
  }
  .project_short_description .button_box a {
    font-size: 14px;
    min-width: inherit;
    padding: 5px 20px;
  }
  .product_innerchatbox,
  .chat_inputarea {
    padding: 10px;
  }
  .sender_name,
  .chat_bubble {
    font-size: 14px;
  }
  .chat_bubble {
    padding: 5px 15px;
  }
  .offer_accept {
    padding: 15px;
    margin: 10px -10px;
  }
  .offer_accept img {
    height: 32px;
  }
  .offer_accept h4 {
    font-weight: normal;
    font-size: 16px;
    margin: 5px 0;
  }
  .offer_accept p {
    font-size: 12px;
    line-height: 18px;
  }
  .attach_icon {
    left: 22px;
    top: 29px;
    height: 18px;
  }
  .chat_inputarea .form-control {
    font-size: 16px;
    padding: 15px 45px;
  }
  .chat_inputarea button {
    right: 18px;
    top: 26px;
    font-size: 20px;
  }
  .bid_message_content.message_chatbox {
    padding: 0 0 73px 0;
  }
  .service_category_main_box .tab_box {
    width: 100%;
  }
  .service_category_main_box .tab_box ul li {
    width: 50%;
  }
  .bid_message_content .nav.nav-tabs li a,
  .bid_message_content .nav.nav-tabs li button {
    max-width: 100%;
    text-align: center;
    padding: 7px 35px;
    font-size: 14px;
  }
  .close_gallery {
    height: 18px;
  }
  .bid_message_content ul li .left_content {
    /*padding: 0;*/
  }
  .bid_message_content ul li .left_content img {
    /*  position: inherit;*/
    margin-bottom: 10px;
  }
  .bid_message_content ul li .right_content {
    padding-top: 28px;
  }
  .bid_message_content .button_box a {
    font-size: 14px;
    padding: 5px 15px;
  }
  .makepayment-modal .modal-body .make_payment_list li {
    float: inherit;
    width: 100%;
    margin: 0px;
    text-align: center;
  }
  .invites-button {
    float: inherit;
    padding-top: 0px;
    margin-top: 15px;
    display: inline-block;
  }
  .tab_heading_content .nav-tabs > li > a {
    min-width: inherit;
  }
  .modal-dialog {
    transform: scale(0.7) !important;
  }
  .my-transaction_box .tab_content_box h1 {
    font-size: 18px;
  }
  .my-transaction_box .tab_content_box h5 {
    font-size: 13px;
    position: relative;
    padding-left: 20px;
    margin-top: 5px;
  }
  .my-transaction_box .tab_content_box h5 span.calender_image {
    width: 16px;
    height: 16px;
  }
  .my-transaction_box .payment-by_box p {
    font-size: 13px;
  }
  .my-transaction_box .tab_content_box .more_content {
    font-size: 13px;
  }
  .my-transaction_box .tab_content_box .more_content .more a {
    font-size: 13px;
  }
  .my-transaction_box .milestones_box h1.heading {
    font-size: 14px;
  }
  .my-transaction_box .milestones_box_content span.rouned {
    width: 20px;
    height: 20px;
    font-size: 13px;
    padding: 1px 0px 0px 0px;
    line-height: normal;
  }
  .my-transaction_box .milestones_box_content {
    padding-left: 25px;
  }
  .my-transaction_box .milestones_box_content h3 {
    font-size: 13px;
  }
  .my-transaction_box .milestones_box_content h3 span {
    font-size: 13px;
  }
  .my-transaction_box .milestones_box_content p {
    font-size: 13px;
  }
  .my-transaction_box .milestones_box_content p span img {
    width: 15px;
    height: 15px;
  }
  .tab_content_box h1 {
    font-size: 14px;
    padding-right: 0px;
  }
  #upcoming .tab_content_box h1 span {
    position: inherit;
    top: 0;
    font-size: 15px;
  }
  .tab_content_box h1 {
    font-size: 14px;
    padding-right: 0px;
  }
  .In-progress {
    font-size: 14px;
  }
  #past .tab_content_box h1 span {
    position: inherit;
    top: 0;
    font-size: 15px;
  }
  .calender_image img {
    width: 17px;
    height: 17px;
  }
  .tab_content_box h5 {
    font-size: 13px;
    padding-left: 24px;
  }
  .tab_content_box .more_content {
    font-size: 14px;
  }
  .tab_content_box .more_content .more a {
    font-size: 14px;
  }
  .tab_content_box h6 a {
    font-size: 14px;
  }
  .tab_content_box {
    padding: 10px 20px 20px 20px;
  }
  .tab_content_box h1 a {
    line-height: normal;
    display: inline-block;
    max-width: 296px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .tab_content_box .dropdown.dropdown_box img {
    width: 25px;
    height: 25px;
    border-radius: 100%;
  }
}

@media screen and (max-width: 480px) {
  .footer_copy_right {
    padding: 30px 0px;
  }
  .heading-area h1 {
    font-size: 30px;
  }
  .new_job_main_box {
    padding: 20px 20px 30px 20px;
    margin-top: 20px;
  }
  .button_box .social_button_links {
    font-size: 14px;
  }
  .select-accout-heading {
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    margin: 15px 0px;
  }
  .upload-btn-wrapper {
    text-align: center;
  }
  .file_upload_box {
    margin: 20px 0px 20px 0px;
    text-align: center;
  }
  .new_modal_content .sign_up_bottom {
    margin: 20px 0px 0px 0px;
  }
  .upload-btn-wrapper span {
    margin-left: 0px;
    display: block;
    margin-top: 15px;
  }
  .chek_box_label {
    font-size: 13px;
  }
  .attachment {
    float: inherit;
  }
  .upload-box {
    text-align: center;
    margin-top: 15px;
  }
  .post_button_box {
    max-width: 100%;
    margin: 0px;
  }
  .bid_message_content ul li .message p {
    font-size: 13px;
  }
  .bid_message_content ul li .mileston p {
    font-size: 13px;
  }
  .bid_message_content ul li .mileston .mileston_list {
    padding: 0px 0px 0px 32px;
  }
  .bid_message_content ul li .mileston span {
    font-size: 13px;
  }
  .progress_bottom p {
    font-size: 15px;
  }
  .skip_button_content .button_box a {
    margin-right: 0px;
    display: block;
    margin-bottom: 15px;
    font-size: 15px;
  }
  .job_post_succesfully {
    padding: 30px 0px 30px 0px;
    background: #e4f1e5;
  }
  .job_post_succesfully h1 {
    font-size: 22px;
  }
  .job_post_succesfully p {
    font-size: 15px;
  }
  .top_servies_heading .left_content h1 {
    font-size: 24px;
  }
  .pink_button {
    font-size: 14px;
  }
  .tab-content.new_job_main_box h2 {
    font-size: 24px;
  }
  .form_input_box .input_box img {
    right: 12px;
  }
  .form_input_box .form-control {
    font-size: 15px;
  }
  .profile_detail_outer label {
    font-size: 16px;
    margin-top: 20px;
  }
  .profile_detail_outer h1 {
    padding-top: 30px;
    font-size: 28px;
  }
  .payment_method-details p img {
    margin-right: 0px;
  }
  .payment_method-details p {
    font-size: 15px;
  }
  .bid_message_content ul li .left_content img {
    width: 50px;
    height: 50px;
    top: 0;
  }
  .bid_message_content ul li .left_content {
    padding: 0px 0px 0px 60px;
  }
  .bid_message_content ul li .left_content {
    width: 70%;
  }
  .span_btn_style span {
    margin: 0px 0px 10px 0px;
    display: block;
    text-align: center;
  }
  .bid_message_content ul li .right_content {
    padding: 0;
  }
  .make_payment_content h2 {
    font-size: 18px;
  }
  .make_payment_content h5 {
    font-size: 15px;
  }
  .make_payment_content h5 span {
    font-size: 14px;
  }
  .payment_method_content .add_new {
    font-size: 18px;
    color: #f16a6e;
    font-weight: 500;
    padding: 5px 0px 30px 0px;
    display: inline-block;
  }
  .tab_bottom_button a {
    font-size: 15px;
  }
  .category_content p {
    position: relative;
    padding-left: 57px;
    margin-bottom: 30px;
  }
  .category_content p {
    font-size: 15px;
  }
  .category_content p span {
    font-size: 15px;
    width: 30px;
    height: 30px;
    padding: 2px 0px 0px 0px;
    margin-right: 0px;
    position: absolute;
    left: 0px;
    right: 0px;
  }
  .service_category_main_box .nav-tabs > li > a {
    padding: 13px 41px;
  }
  .service_category_main_box .tab_box {
    width: 300px;
    margin: auto;
  }
  /** 9 april **/
  .tab_heading_content .nav-tabs > li > a,
  .profile-info-List li {
    padding: 10px;
  }
  .profile-info-List .content img {
    width: 60px;
    height: 60px;
  }
  .profile-info-List .content {
    padding: 10px 0px 10px 75px;
  }
  .project_short_description p .view_message {
    position: inherit;
  }
  .project_short_description .button_box a {
    font-size: 12px;
    padding: 3px 12px;
  }
  .view_gallery {
    font-size: 11px;
    margin-top: 4px;
  }
  .view_gallery img {
    display: none;
  }
  .view_bid_main_box .heading h1 {
    font-size: 16px;
  }
  .gallery_image_box img {
    width: 100%;
    height: 105px;
  }
  .new_modal_content .modal-header {
    padding: 10px 15px;
  }
  .new_modal_content .modal-title {
    font-size: 20px;
  }
  .makepayment-modal .modal-body .accept {
    font-size: 18px;
  }
  .makepayment-modal .modal-body h4 {
    font-size: 16px;
    margin-top: 18px;
  }
  .radio_button_box .radio_box {
    font-size: 15px;
    padding-top: 0px;
  }
  .makepayment-modal .modal-body h5 {
    font-size: 14px;
    padding: 20px 0px 20px 0px;
  }
  .makepayment-modal .modal-body h5 span {
    font-size: 15px;
  }
  .modal-body .select_list {
    font-size: 18px;
  }
  .upload_content .btn {
    padding: 14px 29px;
    font-size: 13px;
  }
  #my_transaction .pull-left {
    float: inherit !important;
    text-align: center;
    margin-bottom: 15px;
  }
  #my_transaction .date_picker.inline-datepicker {
    max-width: inherit;
  }
  #my_transaction .pull-right {
    float: inherit !important;
  }
  .my-transaction_box .payment-by_box p {
    position: absolute;
    left: 0px;
  }
  .my-transaction_box .tab_content_box .more_content {
    margin: 28px 0px 0px 0px;
  }
  .my-transaction_box .tab_content_box h1 {
    font-size: 15px;
  }
  .my-transaction_box .milestones_box_content h3 {
    font-size: 11px;
    position: relative;
  }
  .my-transaction_box .milestones_box_content h3 span {
    position: absolute;
    left: 0px;
    top: 15px;
  }
  .my-transaction_box .milestones_box_content p {
    font-size: 11px;
    margin-top: 17px;
    position: relative;
  }
  .my-transaction_box .milestones_box_content p span {
    position: absolute;
    left: 0px;
    top: 19px;
  }
  .tab_content_box h1 a {
    max-width: 200px;
  }
}

@media screen and (max-width: 399px) {
  .tab_content_box h1 a {
    max-width: 138px;
  }
  .tab_content_box h1 span.waiting {
    left: 0px;
  }
  .notificaition-dropdown-menu {
    left: -197px;
  }
  .notificaition-dropdown-menu {
    width: 283px;
    top: 50px;
  }
}

/*media here*/

.listing-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 300px));
  grid-gap: 20px;
  padding: 20px;
}

.listing-grid-item {
  width: 300px;
  border-radius: 10px;
  overflow: hidden;
}

.listing-grid-item img {
  border-bottom: 1px solid #ddd;
}

.listing-grid-item h3 {
  margin: 10px;
  font-size: 1.2em;
}

.listing-grid-item p {
  margin: 10px;
  font-size: 1em;
}

.submit_bid .btn-box button {
  border: 1px solid #5c90ff;
  margin-bottom: 10px;
  background: #5c90ff;
  color: #fff;
  border-radius: 50px;
  padding: 3px 15px;
  margin-left: 10px;
  font-weight: 600;
  letter-spacing: 0.5px;
  min-width: 90px;
}
.submit_bid .btn-box {
  text-align: right;
}
.submit_bid .btn-box button:first-child {
  margin-left: 0;
}
.submit_bid .milestone_add_box {
  border: 1px solid #f2f2f2;
  padding: 20px 15px;
  margin: 20px 0;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.05);
}
.submit_bid .view_job_details {
  font-size: 16px;
  color: #fff;
  display: inline-block;
  margin: 13px 0px 23px 0px;
  text-decoration: none;
  background: #79a1f6;
  padding: 10px 20px;
  border-radius: 100px;
  font-weight: 600;
}
.submit_bid .view_job_details:hover {
  background: #79a1f6;
  color: #fff;
  text-decoration: none;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
}
