@import url("../src/assets/css/bootstrap.min.css");
@import url("../src/assets/css/font-awesome.css");
@import url("./assets/css/style.css");

@import url("../src/assets/provider/css/bootstrap.min.css");
@import url("../src/assets/provider/css/datepicker.css");
@import url("./assets/provider/css/style.css");
/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
.modal-content {
  max-width: 470px;
  margin: 50px auto auto auto;
}

.image-checkbox {
  cursor: pointer;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border: 4px solid transparent;
  margin-bottom: 0;
  outline: 0;
  position: relative;
}
.image-checkbox input[type="checkbox"] {
  display: none;
}

.image-checkbox-checked {
  border-color: #79a1f6;
}
.image-checkbox-oldddd .fa {
  position: absolute;
  color: #fff;
  background-color: #f16a6e;
  padding: 10px;
  top: 0;
  right: 0;
}

.image-checkbox .fa {
  position: absolute;
  color: #fff;
  background-color: #f16a6e;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 18px;
  line-height: 40px;
}

.image-checkbox-checked .fa {
  display: block !important;
}

/*fix-header-css here*/
.darkhomeHeader {
  position: fixed;
  z-index: 1;
  width: 100%;
  left: 0px;
  right: 0px;
}
.darkHeader {
  position: fixed;
  background: #7aa2f3;
  top: 0px;
  z-index: 9;
}
.darkHeader .navbar {
  margin-bottom: 0px;
}
/*fix-header-css here*/

.react-datepicker-popper {
  transform: scale(1.4) !important;
  top: 82px !important;
  left: 30px !important;
}
.modal-body .upload_content .btn {
  border: 1px solid #79a1f6;
  color: #fff;
  background-color: #79a1f6;
  padding: 13px 29px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}

.new_job_main_box .upload_content .btn {
  border: 1px solid #79a1f6;
  color: #fff;
  background-color: #79a1f6;
  padding: 8px 21px;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
}
.new_job_main_box .validation {
  font-size: 18px;
  padding-bottom: 20px;
  padding-right: 106px;
}
.has_data ._2iA8p44d0WZ-WqRBGcAuEV {
  height: auto !important;
}
.has_data input {
  opacity: 0;
}
.pagination_box .pagination .active a {
  background: #7aa2f3;
  color: #fff;
  border: 1px solid #7aa2f3;
}
.thumbs-wrapper.axis-vertical {
  display: none;
}
.carousel .slide {
  background-color: rgba(0, 0, 0, 0) !important;
}
.listing-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 40px;
  padding: 20px;
}

.listing-grid-item {
  width: 300px;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 100px;
}

.listing-grid-item img {
  border-bottom: 1px solid #ddd;
}

.listing-grid-item h3 {
  margin: 10px;
  font-size: 1.2em;
}

.listing-grid-item p {
  margin: 10px;
  font-size: 1em;
}
.more_content,
.description_content {
  word-break: break-all;
}

i.fa.fa-eye.image_preview {
  float: left;
  margin: 0 171px 19px 11px;
}

.textAlignCenter {
  text-align: center;
}

/* Modal Content (image) */
.iamge-content {
  margin: 83px 27px 17px 53px;
  display: block;
  width: 80%;
  max-width: 700px;
}

/* Caption of Modal Image */
#caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}

/* Add Animation */
.iamge-content,
#caption {
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
  }
}

@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.selected_image {
  padding: 12px 18px 12px 3px;
  display: inline-block;
}

i.fa.fa-trash.selected_image_delete {
  /* margin: 17px 33px 23px 8px; */
  top: -49px;
  position: relative;
  margin: 0px 0px 0px 0px;
}

i.fa.fa-trash.selected_image_delete.color_red {
  color: red;
}

.tab_heading_content .nav-tabs > li.upcoming_list > a {
  padding: 12px !important;
}

.no-text-message {
  top: 46%;
}

a.btn.btn-danger.pink_button.cancel-job {
  padding: 6px 3px 5px 5px;
}

.payment-section-area .makepayment-box {
  padding: 0px;
  min-height: 652px;
}

.pre_css {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  /* color: #333; */
  word-break: break-all;
  word-wrap: break-word;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 4px;
}
/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
  .modal-content {
    width: 100%;
  }
  .tab_heading_content .nav-tabs > li.upcoming_list > a {
    padding: 10px !important;
  }
}
